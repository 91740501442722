import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/store/app.reducer";
import { Router } from "@angular/router";
import { debounceTime, filter, Subject, take, takeUntil, timer } from "rxjs";
import { CurrenciesService } from "src/app/core/services/currencies.service";
import { LendingService } from "src/app/core/services/lending.service";
import { StatsService } from "src/app/core/services/stats.service";
import { Currencies } from "src/app/shared/interfaces/currencies/currencies";
import { FifStats } from "src/app/shared/interfaces/fif-stats";
import { FixedIncomeFund } from "src/app/shared/interfaces/fifs/fixedIncomeFund";
import { User } from "src/app/shared/interfaces/user/user";
import {
  CRYPTO_ICONS,
  GENERAL_ICONS,
  MENU_ICONS
} from "src/assets/images/svg-icons";
import { DashboardStats } from "src/app/shared/interfaces/statistics/dashboard-stats";

@Component({
  selector: "app-fifs",
  templateUrl: "./fifs.component.html",
  styleUrls: ["./fifs.component.scss"],
})
export class FifsComponent implements OnInit, OnDestroy {
  public cryptoIcons = CRYPTO_ICONS;
  public generalIcons = GENERAL_ICONS;
  public arrow = MENU_ICONS.rightArrow;
  public fixedIncomeFunds: FixedIncomeFund[] = [];
  public sortedBy: string;
  public user: User;
  public currencies: Currencies;
  public stats: DashboardStats;
  public fifStats: FifStats = {
    activeFifs: 0,
    totalDeposited: 0,
    totalInvested: 0,
    platformInvested: 0,
  }
  private destroy$ = new Subject<void>();
  public isFetching: boolean = false;

  constructor(
    private store: Store<AppState>,
    private lendingService: LendingService,
    private currenciesService: CurrenciesService,
    private router: Router,
    private statsService: StatsService
  ) {
    this.currenciesService.fetchCurrencies ? this.currenciesService.fetchCurrencies() : false;
    this.statsService.getDashboardStats ? this.statsService.getDashboardStats() : false;
    this.store.select(state => state.dashboard.stats)
      .pipe(
        filter(platformStats => !!platformStats),
        takeUntil(this.destroy$)
      )
      .subscribe((platformStats => {
        this.fifStats.platformInvested = platformStats.getTVL?.lendingTVL;
      }))
  }

  ngOnInit(): void {
    this.isFetching = true;
    timer(1000).pipe(takeUntil(this.destroy$))
      .subscribe(async () => {
        this.store.select(state => state.core.user)
          .pipe(filter(user => !!user), takeUntil(this.destroy$))
          .subscribe((user: User) => {
            this.user = user;
            this.lendingService.getFixedIncomeFunds(this.user.ethAddress, false);
          })

        this.store.select(store => store.lending.fixedIncomeFunds)
          .pipe(takeUntil(this.destroy$))
          .subscribe((fixedIncomeFunds) => {
            if (!fixedIncomeFunds && fixedIncomeFunds?.length <= 0) {
              this.router.navigate(["/sell"]);
            }
            else {
              this.isFetching = false;

              this.store.select('dashboard')
                .pipe(takeUntil(this.destroy$))
                .subscribe((dashboardStore => {
                  this.stats = dashboardStore.stats;
                  if (!this.stats) {
                    this.statsService.getDashboardStats();
                  }
                }))

              this.store.select(store => store.lending.fixedIncomeFunds)
                .pipe(filter(fixedIncomeFunds => !!fixedIncomeFunds), takeUntil(this.destroy$))
                .subscribe((fixedIncomeFunds) => {
                  this.fifStats.totalDeposited = 0;
                  this.fifStats.totalInvested = 0;
                  this.fifStats.activeFifs = 0;
                  this.fixedIncomeFunds = fixedIncomeFunds;
                  this.fifStats.activeFifs = this.fixedIncomeFunds.length;
                  this.fixedIncomeFunds.forEach((fund: FixedIncomeFund) => {
                    this.fifStats.totalDeposited += +this.currenciesService.weiToCurrency(fund.availableFunds, fund.underlying, 'USD') + +this.currenciesService.weiToCurrency(fund.activeInvested, fund.underlying, 'USD');
                    this.fifStats.totalInvested += +this.currenciesService.weiToCurrency(fund.activeInvested, fund.underlying, 'USD');
                  })
                });

              // this.store.select(state => state.core.user)
              //   .pipe(filter(user => !!user), takeUntil(this.destroy$))
              //   .subscribe((user) => {
              //     this.user = user;
              //     if (this.user && this.user.ethAddress) {
              //       timer(0, 3000)
              //         .pipe(
              //           take(3),
              //           takeUntil(this.destroy$)
              //         )
              //         .subscribe(() => {
              //           this.lendingService.getFixedIncomeFunds(this.user.ethAddress, false);
              //         })


              //       this.store.select(store => store.lending.fixedIncomeFunds)
              //         .pipe(take(1), takeUntil(this.destroy$))
              //         .subscribe((fixedIncomeFunds) => {
              //           if (!fixedIncomeFunds) {
              //             this.router.navigate(["/sell"]);
              //           }
              //         })

              //     }
              //   });

              this.store.select("dashboard")
                .pipe(takeUntil(this.destroy$))
                .subscribe((dashboardStore) => {
                  this.currencies = dashboardStore.currencies;
                });

            }
          })
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
