import { createAction, props } from "@ngrx/store";
import { CreditLine } from "src/app/shared/interfaces/borrowing/credit-line";
import { Loan, LoanStates } from "src/app/shared/interfaces/borrowing/loan";
import { LoanData } from "src/app/shared/interfaces/borrowing/loan-data";
import { RiskRateFee } from "src/app/shared/interfaces/borrowing/risk-rate-fee";
import { Currency } from "src/app/shared/interfaces/currencies/currency";

export const initializeBorrowingForm = createAction(
  'initializeBorrowingForm',
  props<{
    riskRateFees: RiskRateFee[],
    paramsInformation: {
      riskFreeRateLogBase: number,
      basePartnerFee: number,
      maxLoanAmount: number,
      minLoanAmount: number,
      minLoanTerm: number,
      maxLoanTerm: number,
      stableCoinsDepegThreshold: number,
      campaignReward: number
    }
  }>()
)

export const getCreditLines = createAction(
  'getCreditLines',
  props<{
    creditLines: CreditLine[],
  }>()
)

export const getCreditLine = createAction(
  'getCreditLine',
  props<{
    creditLine: CreditLine,
  }>()
)

export const initializeBorrowingDashboard = createAction(
  'initializeBorrowingDashboard',
  props<{
    borrowedLoanSummary: {}[],
    creditLines: CreditLine[]
  }>()
)

export const getCreditLineDetails = createAction(
  'getCreditLineDetails',
  props<{
    creditLine: CreditLine,
  }>()
)

export const getLoans = createAction(
  'getLoanDetails',
  props<{
    loan: Loan
  }>()
)
export const getLoan = createAction(
  'getLoanDetail',
  props<{
    loan: Loan
  }>()
)
export const updateLoanData = createAction(
  'storeLoanData',
  props<{
    loanData: LoanData
  }>()
)

export const creatingLoanRequest = createAction(
  'createLoanRequest',
  props<{
    creatingLoanRequest: boolean
  }>()
)

export const creatingLoan = createAction(
  'startLoan',
  props<{
    creatingLoan: boolean
  }>()
)


export const updateLoanAsset = createAction(
  'updateLoanAsset',
  props<{
    loanAsset: Currency
  }>()
)

export const updateLoanCollateral = createAction(
  'updateLoanCollateral',
  props<{
    loanCollateral: Currency
  }>()
)

export const addCreditLineCurrencies = createAction(
  'addCreditLineCurrencies',
  props<{
    creditLine: CreditLine,
    assets: Currency[],
    collaterals: Currency[]
  }>()
)

export const depositingCollateral = createAction(
  'depositingCollateral',
  props<{
    depositingCollateral: boolean
  }>()
)

export const repayingLoan = createAction(
  'repayingLoan',
  props<{
    repayingLoan: boolean
  }>()
)

export const approvingRepayment = createAction(
  'approvingRepayment',
  props<{
    approvingRepayment: boolean
  }>()
)

export const increasingCollateral = createAction(
  'increasingCollateral',
  props<{
    increasingCollateral: boolean
  }>()
)

export const updateLoan = createAction(
  'updateLoan',
  props<{
    loan: Loan
  }>()
)
export const updateLoans = createAction(
  'updateLoans',
  props<{
    loan: Loan
  }>()
)

// export const updateCreditLineAssets = createAction(
//   'updateCreditLineAsset',
//   props<{
//     asset: Currency,
//     creditLine: CreditLine
//   }>()
// )

export const updateCreditLineCollaterals = createAction(
  'updateCreditLineCollateral',
  props<{
    collaterals: Currency[],
    creditLine: CreditLine
  }>()
)

export const updateCreditLine = createAction(
  'updateCreditLine',
  props<{
    creditLine: CreditLine
  }>()
)

export const setLoanStates = createAction(
  'setLoanStates',
  props<{
    loan: Loan
    states: LoanStates
  }>()
)

export const updateParamsInformation = createAction(
  'updateParamsInformation',
  props<{
    paramsInformation: {
      riskFreeRateLogBase?: number,
      basePartnerFee?: number,
      maxLoanAmount?: number,
      minLoanAmount?: number,
      minLoanTerm?: number,
      maxLoanTerm?: number,
      stableCoinsDepegThreshold?: number,
      campaignReward?: number
      partnerFee?: number
    }
  }>()
)

export const getLastWeekRewards = createAction(
  'getLastWeekRewards',
  props<{
    lastWeekRewards: any
  }>()
)

export const updateLoanOver1kAwarded = createAction(
  'updateLoanOver1kAwarded',
  props<{
    loanOver1kAwarded: boolean
  }>()
)

export const resetState = createAction(
  'resetState'
)
