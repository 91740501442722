import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, Subject, takeUntil, take } from 'rxjs';
import { User } from 'src/app/shared/interfaces/user/user';
import { AppState } from 'src/app/store/app.reducer';
import { LegendPosition, ScaleType } from '@swimlane/ngx-charts';
import { GENERAL_ICONS, MENU_ICONS } from 'src/assets/images/svg-icons';
import { UserService } from 'src/app/core/services/user.service';
import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { EmailAddDialog } from "../../components/email-add/email-add.dialog";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit, OnDestroy {
  public user: User;
  public baseUrl = environment.baseUrl;
  public icons = { ...GENERAL_ICONS, ...MENU_ICONS };
  public copyText: string = 'Copy Link';
  public chartData: any;
  private telegramBotLink: string;
  private destroy$ = new Subject<void>();
  private emailDialogRef: DialogRef<any>

  view: [number, number] = [110, 80];
  legendPosition: LegendPosition = LegendPosition.Below;
  colorScheme = {
    name: 'cool',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#a8385d']
  }

  constructor(
    private store: Store<AppState>,
    private userService: UserService,
    public dialog: Dialog
  ) { }

  ngOnInit(): void {
    this.store.select(state => state.core.user)
      .pipe(filter(user => !!user), takeUntil(this.destroy$))
      .subscribe((user: User) => {
        this.user = user;
      })

    this.chartData = [{ name: 'Risk Rating', value: this.user ? this.user.creditData.riskRating : 1 }]
  }

  enableTelegramNotification() {
    this.userService.enableTelegramNotification()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.telegramBotLink = res;
        window.open(this.telegramBotLink);
      });
  }

  openAddEmailDialog() {
    this.emailDialogRef = this.dialog.open(EmailAddDialog, {
      id: 'add-email',
      minWidth: '300px',
      maxWidth: '300px',
      autoFocus: false,
      data: {
        email: this.user.email
      }
    });
    this.emailDialogRef.componentInstance['added']
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe((email) => {
        this.userService.updateEmail({ email })
        this.emailDialogRef.close();
      })
    this.emailDialogRef.componentInstance['reject']
    .pipe(take(1), takeUntil(this.destroy$))
    .subscribe(() => {
      this.emailDialogRef.close();
    })
  }

  goToTwitter() {
    window.open('https://x.com/Baraka_Fi_', '_blank');
  }

  copy() {
    this.copyText = 'Copied!';
    setTimeout(() => {
      this.copyText = 'Copy Link';
    }, 1000);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
