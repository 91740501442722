import { gql } from "apollo-angular";
// ? OnFormInit
export const borrowingFormInitQuery = gql`
  query {
    riskRateFees {
      rating
      marginRate
      maxInsurancePremiumRate
      platformFee
    }
    # loanRequestValidators {
    #   partnerFee
    # },
    paramsInformation {
      riskFreeRateLogBase
      basePartnerFee
      maxLoanAmount
      minLoanAmount
      minLoanTerm
      maxLoanTerm
      stableCoinsDepegThreshold
      campaignReward
      borrowRewardToken
    }
  }
`;

export const creditLinesQuery = gql`
  query ($where: JSON) {
    creditLines(where: $where) {
      ownerAddress
      contractAddress
      type
      createdAt
      updatedAt
      collateralStats {
        lockedCollateralAmount
        lockedCollateralValueInUSD
        unlockedCollateralAmount
        unlockedCollateralValueInUSD
        minCollateralRatio
        currency {
          symbol
          name
          ethAddress
          riskFreeRate
          exchangeRate
          medianExchangeRate
          creditCoinAddress
          creditCoinSymbol
          isUnderlying
          isCollateral
          decimalPlaces
        }
      }
      underlyingStats {
        totalBorrowedAmount
        currency {
          symbol
          name
          ethAddress
          riskFreeRate
          exchangeRate
          medianExchangeRate
          creditCoinAddress
          creditCoinSymbol
          isUnderlying
          isCollateral
          decimalPlaces
        }
      }
    }
  }
`;

export const creditLineQuery = gql`
  query ($contractAddress: String!) {
    creditLine(contractAddress: $contractAddress) {
      ownerAddress
      contractAddress
      type
      collateralStats {
        lockedCollateralAmount
        lockedCollateralValueInUSD
        unlockedCollateralAmount
        unlockedCollateralValueInUSD
        minCollateralRatio
        currency {
          symbol
          name
          ethAddress
          riskFreeRate
          exchangeRate
          medianExchangeRate
          creditCoinAddress
          creditCoinSymbol
          isUnderlying
          isCollateral
          decimalPlaces
        }
      }
      underlyingStats {
        totalBorrowedAmount
        currency {
          symbol
          name
          ethAddress
          riskFreeRate
          exchangeRate
          medianExchangeRate
          creditCoinAddress
          creditCoinSymbol
          isUnderlying
          isCollateral
          decimalPlaces
        }
      }
      createdAt
      updatedAt
    }
  }
`;
// ? OnFormValueChange
export const requiredCollateralRatioQuery = gql`
  query (
    $amount: Float!
    $duration: Int!
    $validity: DateTime!
    $collateral: String!
    $underlying: String!
    $riskRating: Int!
  ) {
    requiredCollateralRatio(
      loanAmount: $amount
      loanTerm: $duration
      loanRequestValidity: $validity
      collateralCurrency: $collateral
      underlyingCurrency: $underlying
      userRating: $riskRating
    ) {
      collateralRatio # collateralRatio = number
    }
  }
`;

export const liquidityPoolQuery = gql`
  query ($currencySymbol: String!) {
    liquidityPool(currencySymbol: $currencySymbol) {
      totalLockedCollateralVolumeInUSD
      maxLoanCollateral
    }
  }
`;

// ? Dashboard
export const borrowingDashboardInitQuery = gql`
  query (
    $walletAddress: String!
    $order: String
    $limit: Int
    $offset: Int
    $where: JSON
  ) {
    borrowedLoanSummary(walletAddress: $walletAddress) {
      key
      value
    }
    creditLines(where: $where, order: $order, limit: $limit, offset: $offset) {
      ownerAddress
      contractAddress
      type
      createdAt
      updatedAt
    }
  }
`;

export const loanQuery = gql`
  query ($orderbookId: ID!) {
    loanRequest(id: $orderbookId) {
      id
      loanAmount
      loanTerm
      netLoanInterestRate
      loanInsurancePremium
      contractAddress
      platformFee
      loanRequestValidity
      loanStatus
      liquidationStatus
      userRating
      collateralInfo {
        ratio
        amount
      }
      underlying {
        symbol
        name
        ethAddress
        exchangeRate
        decimalPlaces
      }
      collateral {
        name
        symbol
        ethAddress
        exchangeRate
        decimalPlaces
      }
      createdAt
      updatedAt
      loanStartDate
      loanEndDate
      loanCloseDate
      lender
      borrower
      lenderAddress
      borrowerAddress
      currentCollateralAmount
      deadline
      signature
      insurerAddress
    }
    loanRequestValidators {
      partnerFee
    }
  }
`;

export const loansQuery = gql`
  query ($where: JSON) {
    loanRequests(where: $where) {
      id
      loanAmount
      loanTerm
      netLoanInterestRate
      loanInsurancePremium
      contractAddress
      platformFee
      loanRequestValidity
      loanStatus
      liquidationStatus
      userRating
      collateralInfo {
        ratio
        amount
      }
      underlying {
        symbol
        name
        ethAddress
        exchangeRate
        decimalPlaces
      }
      collateral {
        name
        symbol
        ethAddress
        exchangeRate
        decimalPlaces
      }
      createdAt
      updatedAt
      loanStartDate
      loanEndDate
      loanCloseDate
      lender
      borrower
      lenderAddress
      borrowerAddress
      currentCollateralAmount
      deadline
      signature
      insurerAddress
    }
  }
`;

export const loanAdditionalDetailsQuery = gql`
  query (
    $loanUsdValue: String!
    $collateralUsdValue: String!
    $underlyingSymbol: String!
    $collateralSymbol: String!
    $userRating: Int!
    $duration: Int!
  ) {
    liquidationProbability(
      loanUSDValue: $loanUsdValue
      collateralUSDValue: $collateralUsdValue
      underlyingSymbol: $underlyingSymbol
      collateralSymbol: $collateralSymbol
      userRating: $userRating
      duration: $duration
    ) {
      collateralNeeded
      liquidationProbability
      liquidationLimit
    }
    loanRequestValidators {
      partnerFee
    }
  }
`;

export const createLoanRequest = gql`
  mutation (
    $loanAmount: Float!
    $loanTerm: Int!
    $loanRate: Float!
    $collateralAmount: Float!
    $loanRequestValidity: Date!
    $collateralCurrency: String!
    $underlyingCurrency: String!
    $userRating: Int!
    $creditLineAddress: String!
    $borrowerAddress: String!
    $type: String!
  ) {
    createLoanRequest(
      input: {
        data: {
          loanAmount: $loanAmount
          loanTerm: $loanTerm
          loanRate: $loanRate
          loanRequestValidity: $loanRequestValidity
          collateralAmount: $collateralAmount
          collateralCurrency: $collateralCurrency
          underlyingCurrency: $underlyingCurrency
          userRating: $userRating
          creditLineAddress: $creditLineAddress
          borrowerAddress: $borrowerAddress
          type: $type
        }
      }
    ) {
      loanRequest {
        id
        loanAmount
        loanTerm
        netLoanInterestRate
        loanInsurancePremium
        contractAddress
        platformFee
        loanRequestValidity
        loanStatus
        liquidationStatus
        userRating
        collateralInfo {
          ratio
          amount
        }
        underlying {
          symbol
          name
          ethAddress
          exchangeRate
          decimalPlaces
        }
        collateral {
          name
          symbol
          ethAddress
          exchangeRate
          decimalPlaces
        }
        createdAt
        updatedAt
        loanStartDate
        loanEndDate
        loanCloseDate
        lender
        borrower
        lenderAddress
        borrowerAddress
        currentCollateralAmount
        deadline
        signature
        insurerAddress
      }
    }
  }
`;

export const liquidationProbability = gql`
  query (
    $loanUsdValue: String!
    $collateralUsdValue: String!
    $underlyingSymbol: String!
    $collateralSymbol: String!
    $userRating: Int!
    $duration: Int!
  ) {
    liquidationProbability(
      loanUSDValue: $loanUsdValue
      collateralUSDValue: $collateralUsdValue
      underlyingSymbol: $underlyingSymbol
      collateralSymbol: $collateralSymbol
      userRating: $userRating
      duration: $duration
    ) {
      collateralNeeded
      liquidationProbability
      liquidationLimit
    }
  }
`;

export const terminateLoanRequest = gql`
  mutation ($loanRequestId: ID!) {
    terminateLoanRequest(input: { where: $loanRequestId }) {
      loanRequest {
        id
        loanAmount
        loanTerm
        netLoanInterestRate
        loanInsurancePremium
        contractAddress
        platformFee
        loanRequestValidity
        loanStatus
        liquidationStatus
        userRating
        collateralInfo {
          ratio
          amount
        }
        underlying {
          symbol
          name
          ethAddress
          exchangeRate
          decimalPlaces
        }
        collateral {
          name
          symbol
          ethAddress
          exchangeRate
          decimalPlaces
        }
        createdAt
        updatedAt
        loanStartDate
        loanEndDate
        loanCloseDate
        lender
        borrower
        lenderAddress
        borrowerAddress
        currentCollateralAmount
        insurerAddress
      }
    }
  }
`;
