import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import {
  catchError,
  concatMap,
  debounceTime,
  EMPTY,
  filter,
  finalize,
  from,
  map,
  merge,
  Observable,
  of,
  Subject,
  take,
  takeUntil,
  tap,
  throwError,
  timer
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { WalletState, AppState as Web3State } from '@web3-onboard/core/dist/types';
import { PLATFORM } from './abis/platform.abi';
import { BORROWING } from './abis/borrowing.abi';
import { LENDING } from './abis/lending.abi';
import { STAKING } from './abis/staking.abi';
import { REWARDS } from './abis/rewards.abi';
import { COMPOUND_V2 } from './abis/compoundv2.abi';
import { ERC20 } from './abis/erc20.abi';
import BigNumber from 'bignumber.js';
import Onboard from '@web3-onboard/core';
import Web3 from 'web3';
import * as CONFIG from './web3.config';
import { UserService } from '../services/user.service';
import { LoanData } from 'src/app/shared/interfaces/borrowing/loan-data';
import { FixedIncomeFund } from 'src/app/shared/interfaces/fifs/fixedIncomeFund';
import { Loan } from 'src/app/shared/interfaces/borrowing/loan';
import { Currency } from 'src/app/shared/interfaces/currencies/currency';
import { CreditLine } from 'src/app/shared/interfaces/borrowing/credit-line';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { WEB3_TOASTR_CONFIG } from 'src/app/shared/constants';
import { ClaimedReward } from 'src/app/shared/interfaces/rewards/claimed-reward';
import { Reward } from 'src/app/shared/interfaces/rewards/reward';
import { FifBucket } from 'src/app/shared/interfaces/fifs/fif-bucket';
import { isPlatformBrowser } from '@angular/common';
import { AuthChallenge } from 'src/app/shared/interfaces/user/auth-challenge';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { LeverageRequest } from 'src/app/shared/interfaces/leveraging/leverage-request';
import { LeverageBorrows } from 'src/app/shared/interfaces/leveraging/leverage-borrows';
import { LEVERAGE, LIDO_WITHDRAW_ABI } from './abis/leverage.abi';
import { cTokens } from './web3.config';
import { Ethereum } from 'src/app/shared/interfaces/wallet/etherum';
import { ethers } from 'ethers';
@Injectable({
  providedIn: 'root'
})

export class Web3Service implements OnDestroy {
  private provider: any;
  private userAddress: string;
  private destroy$ = new Subject<void>();
  private isBrowser: boolean;
  private onboard = Onboard({
    wallets: CONFIG.WALLETS,
    chains: CONFIG.CHAINS,
    accountCenter: CONFIG.ACCOUNT_CENTER,
    appMetadata: CONFIG.APP_METADATA,
    apiKey: environment.web3.blocknativeId,
    notify: {
      enabled: false
    },
    connect: {
      autoConnectLastWallet: true
    }
  })
  private networkChangedSubject = new Subject<string>();
  networkChanged$ = this.networkChangedSubject.asObservable();

  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private store: Store<AppState>,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      this.web3StateInit();
      this.store.select(state => state.core.user)
        .pipe(
          filter(user => !!user),
          takeUntil(this.destroy$),
          map(user => user.ethAddress))
        .subscribe((userAddress: string) => {
          this.userAddress = userAddress;
        })
      this.networkChangeListeners();
    }

  }

  web3StateInit() {
    this.onboard.state.select()
      .pipe(debounceTime(300), takeUntil(this.destroy$),
        catchError((err) => throwError(() => new Error('Could not initialize web3'))))
      .subscribe((res: Web3State) => {
        if (res.wallets[0]) {
          if (res.wallets[0].accounts.length > 1) {
            this.userService.logOut();
            this.disconnectWallet().pipe(take(1)).subscribe();
          } else {
            this.provider = new Web3(res.wallets[0].provider as any);
            localStorage.setItem('connectedWallets', res.wallets[0].label)
            this.userAddress = res.wallets[0].accounts[0].address;
          }
        }
      });
  }

  connectWallet(): Observable<{ account: string, message: string }> {
    return from(this.onboard.connectWallet())
      .pipe(map((res: WalletState[]) => {
        const isCorrectChain = this.checkChain(res[0]);
        if (isCorrectChain) {
          return { account: this.userAddress, message: environment.web3.walletAuthMessage }
        } else {
          throw new Error('Invalid Chain')
        }
      }), catchError((err) => {
        if (err.message === 'Invalid Chain') {
          return throwError(() => new Error(err.message))
        }
        return throwError(() => new Error('Connection Failed'))
      }))
  }

  checkChain(walletState: WalletState): boolean {
    const currentChain = CONFIG.CHAINS.find(chain => chain.id === (walletState.chains[0] ? walletState.chains[0].id : -1));
    const requiredChain = CONFIG.CHAINS.find(chain => chain.id === environment.web3.chainId);
    const isCorrectChain = currentChain === requiredChain;

    if (!isCorrectChain) {
      this.toastr.warning(`Current network: ${currentChain ? currentChain.label : 'unknown'}`, `You must be on the ${requiredChain!.label} network`);
      this.disconnectWallet().pipe(take(1)).subscribe();
    } else {
      if (walletState.label !== 'WalletConnect') this.setDomain(walletState);
    }
    return isCorrectChain;
  }

  private networkChangeListeners(): void {

    const { ethereum } = window as unknown as { ethereum: Ethereum | undefined };

    if (ethereum) {
      ethereum.on('chainChanged', (chainId: string) => {
        this.networkChangedSubject.next(chainId);
      });
    }
  }



  getProvider(): Observable<any> {
    return of(this.onboard.state.get()).pipe(
      concatMap((s: any) => s.wallets[0] ? of(s.wallets) : from(this.onboard.connectWallet())),
      map((wallets: WalletState[]) => {
        this.provider = new Web3(wallets[0].provider as any)
        return this.provider;
      })
    );
  }

  createAuthMessage({
    address,
    statement,
    chainId,
    nonce,
    issuedAt
  }) {
    // const domain = 'mirror.p2pfinex.com';
    // const origin = 'https://mirror.p2pfinex.com';
    const domain = window.location.host;
    const origin = window.location.origin;
    return `${domain} wants you to sign in with your Haqq account:\n${address}\n${statement}\n\nURI: ${origin}\nVersion: 1\nChain ID: ${chainId}\nNonce: ${nonce}`
  }

  sign(authChallenge: AuthChallenge): Observable<any> {
    const chainId = this.onboard.state.get().wallets[0].chains[0].id;

    const message = this.createAuthMessage({
      address: authChallenge.account,
      statement: authChallenge.message,
      chainId: parseInt(chainId, 16),
      nonce: authChallenge.challenge,
      issuedAt: new Date()
    })
    return this.getProvider().pipe(concatMap((provider: any) => {
      return from(provider.eth.personal.sign(message, authChallenge.account, ''))
        .pipe(
          map((signature: any) => ({ address: this.userAddress, signature })),
          catchError((err) => {
            return throwError(() => new Error(err))
          })
        )
    }))
  }

  setDomain(wallet: WalletState) {
    const domain = wallet.instance ? (wallet.instance as any).user.sub : undefined;
    if (domain) this.userService.updateUserDomain(domain);
  }

  disconnectWallet(): Observable<any> {
    const [primaryWallet] = this.onboard.state.get().wallets;
    if (primaryWallet) {
      return from(this.onboard.disconnectWallet({ label: primaryWallet.label }));
    } else {
      return of(undefined);
    }
  }

  // Lending - Create FIF - PLATFORM - Create FIF
  createFixedIncomeFund$(fundType: string, ratio: number[], tId = -1): Observable<any> {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const hash = provider.utils.keccak256(fundType);
      const salt = provider.utils.randomHex(32);
      const instance = new provider.eth.Contract(PLATFORM.abi, environment.web3.smartCreditContract);
      try {
        return from(instance.methods
          .createFixedIncomeFund(hash, salt, ratio)
          .send({ from: this.userAddress })
          .on('transactionHash', (hash) => {
            const toast = this.toastr.info(`0 seconds ago`, `Creating FIF!`, WEB3_TOASTR_CONFIG);
            tId = toast.toastId;
            timer(0, 1000).pipe(takeUntil(merge(toast.onHidden, this.destroy$))).subscribe(seconds => this.toastr.toasts.find(toast => toast.toastId == tId).toastRef.componentInstance.message = `${seconds} seconds ago`);
            return hash;
          })
          .on('receipt', (receipt) => {
            this.toastr.clear(tId);
            this.toastr.info('You will be redirected to the Financing Dashboard. Please wait...', 'Fixed Income Fund created!');
            return receipt;
          })
          .on('error', (error) => {
            this.toastr.clear(tId);
            return error;
          })
        ).pipe(takeUntil(this.destroy$))
      } catch (e) {
        return this.handleError(e, tId)
      }
    }))
  }

  // ***** LEVERAGE *****

  // Leverage - PLATFORM - Deposits to leverage line
  depositToLeverageLine(leverage: LeverageRequest, tId = -1): Observable<any> {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const instance = new provider.eth.Contract(PLATFORM.abi, environment.web3.smartCreditContract);
      let investmentAmount = new BigNumber(leverage.initialInvestmentAmount).toNumber().toLocaleString('fullwide', { useGrouping: false });
      let leverageShares = !leverage.leverageShares || leverage.leverageShares === '' ? 0 : leverage.leverageShares;
      let interestRate = (leverage.interestRate * 100).toFixed();
      let borrowFeeRate = ((leverage.insurancePremiumRate + leverage.platformFeeRate) * 100).toFixed();
      let leverageFeeRate = (leverage.leverageFeeRate * 100).toFixed();
      let lenders: any = [];
      leverage.borrows.forEach((b: LeverageBorrows) => lenders.push([b.principal, b.lenderAddress]));
      let deadline = Math.floor(new Date(leverage.deadline).getTime() / 1000);
      try {
        return from(
          instance.methods.requestLeverage(
            [
              leverage.leverageId,
              investmentAmount,
              leverageShares,
              leverage.leveragePeriod,
              interestRate,
              borrowFeeRate,
              leverageFeeRate,
              lenders,
              environment.web3.insurerAddress
            ],
            leverage.contractAddress,
            leverage.signature,
            deadline
          )
            .send({
              from: this.userAddress,
              value: investmentAmount,
            })
            .on('transactionHash', (hash) => {
              const toast = this.toastr.info(`0 seconds ago`, `Depositing to leveraged line!`, WEB3_TOASTR_CONFIG);
              tId = toast.toastId;
              timer(0, 1000).pipe(takeUntil(merge(toast.onHidden, this.destroy$))).subscribe(seconds => this.toastr.toasts.find(toast => toast.toastId == tId).toastRef.componentInstance.message = `${seconds} seconds ago`);
              return hash;
            })
            .on('receipt', (receipt) => {
              this.toastr.clear(tId);
              this.toastr.info('', 'Deposited successfully');
              return receipt;
            })
            .on('error', (error) => {
              this.toastr.clear(tId);
              return error;
            })
        ).pipe(takeUntil(this.destroy$))
      } catch (e) {
        return this.handleError(e, tId)
      }
    }))
  }


  repayLeverageCurve(leverage: LeverageRequest, slippage: number, tId = -1) {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const instance = new provider.eth.Contract(LEVERAGE.abi, leverage.contractAddress);
      let investmentAmount = new BigNumber(leverage.initialInvestmentAmount).toNumber().toLocaleString('fullwide', { useGrouping: false });
      let leverageShares = !leverage.leverageShares || leverage.leverageShares === '' ? 0 : leverage.leverageShares;
      let interestRate = (leverage.interestRate * 100).toFixed();
      let borrowFeeRate = ((leverage.insurancePremiumRate + leverage.platformFeeRate) * 100).toFixed();
      let leverageFeeRate = (leverage.leverageFeeRate * 100).toFixed();
      let lenders: any = [];
      leverage.borrows.forEach((b: LeverageBorrows) => lenders.push([b.principal, b.lenderAddress]));
      try {
        return from(
          instance.methods.unwindLeverage(
            [
              leverage.leverageId,
              investmentAmount,
              leverageShares,
              leverage.leveragePeriod,
              interestRate,
              borrowFeeRate,
              leverageFeeRate,
              lenders,
              environment.web3.insurerAddress
            ],
            slippage
          )
            .send({
              from: this.userAddress,
            })
            .on('transactionHash', (hash) => {
              const toast = this.toastr.info(`0 seconds ago`, `Repaying Leveraged Staking Request!`, WEB3_TOASTR_CONFIG);
              tId = toast.toastId;
              timer(0, 1000).pipe(takeUntil(merge(toast.onHidden, this.destroy$))).subscribe(seconds => this.toastr.toasts.find(toast => toast.toastId == tId).toastRef.componentInstance.message = `${seconds} seconds ago`);
              return hash;
            })
            .on('receipt', (receipt) => {
              this.toastr.clear(tId);
              this.toastr.info('', 'Repaid successfully');
              return receipt;
            })
            .on('error', (error) => {
              this.toastr.clear(tId);
              return error;
            })
        ).pipe(takeUntil(this.destroy$))
      } catch (e) {
        return this.handleError(e, tId)
      }
    }))
  }

  leverageEarnedAmount(leverage: LeverageRequest) {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const instance = new provider.eth.Contract(LEVERAGE.abi, leverage.contractAddress);
      try {
        return from(instance.methods.earned(leverage.leverageShares).call())
      } catch (e) {
        return this.handleError(e);
      }
    }))
  }

  repayLeverageLido(leverage: LeverageRequest, hintIds: number[], tId = -1) {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const instance = new provider.eth.Contract(LIDO_WITHDRAW_ABI.abi, leverage.contractAddress);
      let investmentAmount = new BigNumber(leverage.initialInvestmentAmount).toNumber().toLocaleString('fullwide', { useGrouping: false });
      let leverageShares = !leverage.leverageShares || leverage.leverageShares === '' ? 0 : leverage.leverageShares;
      let interestRate = (leverage.interestRate * 100).toFixed();
      let borrowFeeRate = ((leverage.insurancePremiumRate + leverage.platformFeeRate) * 100).toFixed();
      let leverageFeeRate = (leverage.leverageFeeRate * 100).toFixed();
      let lenders: any = [];
      leverage.borrows.forEach((b: LeverageBorrows) => lenders.push([b.principal, b.lenderAddress]));
      try {
        return from(
          instance.methods.unwindLeverage(
            [
              leverage.leverageId,
              investmentAmount,
              leverageShares,
              leverage.leveragePeriod,
              interestRate,
              borrowFeeRate,
              leverageFeeRate,
              lenders,
              environment.web3.insurerAddress
            ],
            hintIds
          )
            .send({
              from: this.userAddress,
            })
            .on('transactionHash', (hash) => {
              const toast = this.toastr.info(`0 seconds ago`, `Repaying Leveraged Staking Request!`, WEB3_TOASTR_CONFIG);
              tId = toast.toastId;
              timer(0, 1000).pipe(takeUntil(merge(toast.onHidden, this.destroy$))).subscribe(seconds => this.toastr.toasts.find(toast => toast.toastId == tId).toastRef.componentInstance.message = `${seconds} seconds ago`);
              return hash;
            })
            .on('receipt', (receipt) => {
              this.toastr.clear(tId);
              this.toastr.info('', 'Repaid successfully');
              return receipt;
            })
            .on('error', (error) => {
              this.toastr.clear(tId);
              return error;
            })
        ).pipe(takeUntil(this.destroy$))
      } catch (e) {
        return this.handleError(e, tId)
      }
    }))
  }

  startLidoWithdraw(leverage: LeverageRequest, tId = -1) {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const instance = new provider.eth.Contract(LEVERAGE.abi, leverage.contractAddress);
      let investmentAmount = new BigNumber(leverage.initialInvestmentAmount).toNumber().toLocaleString('fullwide', { useGrouping: false });
      let leverageShares = !leverage.leverageShares || leverage.leverageShares === '' ? 0 : leverage.leverageShares;
      let interestRate = (leverage.interestRate * 100).toFixed();
      let borrowFeeRate = ((leverage.insurancePremiumRate + leverage.platformFeeRate) * 100).toFixed();
      let leverageFeeRate = (leverage.leverageFeeRate * 100).toFixed();
      let lenders: any = [];
      leverage.borrows.forEach((b: LeverageBorrows) => lenders.push([b.principal, b.lenderAddress]));
      try {
        return from(
          instance.methods.startLidoWithdraw(
            [
              leverage.leverageId,
              investmentAmount,
              leverageShares,
              leverage.leveragePeriod,
              interestRate,
              borrowFeeRate,
              leverageFeeRate,
              lenders,
              environment.web3.insurerAddress
            ],
          )
            .send({
              from: this.userAddress
            })
            .on('transactionHash', (hash) => {
              const toast = this.toastr.info(`0 seconds ago`, `Requesting withdrawal of funds!`, WEB3_TOASTR_CONFIG);
              tId = toast.toastId;
              timer(0, 1000).pipe(takeUntil(merge(toast.onHidden, this.destroy$))).subscribe(seconds => this.toastr.toasts.find(toast => toast.toastId == tId).toastRef.componentInstance.message = `${seconds} seconds ago`);
              return hash;
            })
            .on('receipt', (receipt) => {
              this.toastr.clear(tId);
              this.toastr.info('', 'Requested withdrawal successfully');
              return receipt;
            })
            .on('error', (error) => {
              this.toastr.clear(tId);
              return error;
            })
        ).pipe(takeUntil(this.destroy$))
      } catch (e) {
        return this.handleError(e, tId)
      }
    }))
  }

  lidoWithdrawHints(leverage: LeverageRequest) {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const instance = new provider.eth.Contract(LEVERAGE.abi, leverage.contractAddress);
      try {
        return from(instance.methods.getLidoWithdrawHints([leverage.withdrawId], 1).call())
      } catch (e) {
        return this.handleError(e)
      }
    }))
  }

  lidoWithdrawStatus(leverage: LeverageRequest): Observable<any> {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const instance = new provider.eth.Contract(LEVERAGE.abi, leverage.contractAddress);
      try {
        return from(instance.methods.getLidoWithdrawStatus([leverage.withdrawId]).call())
      } catch (e) {
        return this.handleError(e)
      }
    }))
  }

  // Lending/Leveraging/SmartCredit - PLATFORM - Creates credit/leverage line
  createLine(creditLineType: string, leverage?: boolean, tId = -1): Observable<any> {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const type = creditLineType.toUpperCase().replace(/ /g, '_');
      const salt = provider.utils.randomHex(32);
      const hash = provider.utils.keccak256(type);
      const instance = new provider.eth.Contract(PLATFORM.abi, environment.web3.smartCreditContract);
      try {
        return from(instance.methods.createCreditLine(hash, salt).send({ from: this.userAddress })
          .on('transactionHash', (hash) => {
            const toast = this.toastr.info(`0 seconds ago`, leverage ? `Creating Leveraged Staking Line!` : 'Creating Finance Line!', WEB3_TOASTR_CONFIG);
            tId = toast.toastId;
            timer(0, 1000).pipe(takeUntil(merge(toast.onHidden, this.destroy$))).subscribe(seconds => this.toastr.toasts.find(toast => toast.toastId == tId).toastRef.componentInstance.message = `${seconds} seconds ago`);
            return hash;
          })
          .on('receipt', (receipt) => receipt)
          .on('error', (error) => error)
        ).pipe(tap(() => this.toastr.clear(tId)), takeUntil(this.destroy$));
      } catch (e) {
        return this.handleError(e, tId)
      }
    }))
  }

  // Borrowing - Create Loan - Sign loan request
  signLoan(loanData: LoanData): Observable<any> {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const loanInterestRate = (loanData.loanRate * 100).toFixed(0);
      const loanTerm = +loanData.loanTerm;
      const loanAmount = this.currencyToWei(loanData.loanAmount, loanData.underlyingDecimalPlaces).toString();
      const fee = (loanData.loanFee * 100).toFixed(0)
      const initialCollateralAmount = this.currencyToWei(loanData.collateralAmount, loanData.collateralDecimalPlaces).toString()
      const messageParams = JSON.stringify({
        domain: {
          name: 'Credit Line',
          chainId: environment.web3.ethereumNetworkId,
          version: '1.0.0',
          verifyingContract: loanData.creditLineAddress
        },
        message: {
          loanId: loanData.loanId,
          loanInterestRate,
          loanTerm,
          loanAmount,
          fee,
          initialCollateralAmount,
          collateralAddress: loanData.collateralAddress,
          underlyingAddress: loanData.underlyingAddress
        },
        primaryType: 'LoanRequest',
        types: CONFIG.SIGN_LOAN_TYPES
      });
      return from(provider.currentProvider.request({
        method: 'eth_signTypedData_v4',
        params: [this.userAddress, messageParams],
        from: this.userAddress
      }, (err: any, res: any) => {
        err ? err : res;
      }))
        .pipe(map((sig: string) => {
          if (sig.slice(-2) === '00') {
            sig = sig.slice(0, -2).concat('1B');
          } else if (sig.slice(-2) === '01') {
            sig = sig.slice(0, -2).concat('1C');
          }
          return sig;
        }), takeUntil(this.destroy$))
    }))
  }

  createLoan(loan: Loan, tId = -1): Observable<any> {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const instance = new provider.eth.Contract(PLATFORM.abi, environment.web3.smartCreditContract);
      const insurerAddress = loan.insurerAddress;
      const netLoanInterestRateFormatted = parseFloat((loan.netLoanInterestRate * 100).toFixed(0));
      const loanAmountFormatted = loan.loanAmount.toLocaleString('fullwide', { useGrouping: false });
      const loanFeeFormatted = parseFloat(((loan.loanInsurancePremium + loan.platformFee + loan.partnerFee) * 100).toFixed(0));
      const collateralAmountFormatted = loan.collateralInfo.amount.toLocaleString('fullwide', { useGrouping: false })
      let deadline = Math.floor(new Date(loan.deadline).getTime() / 1000);
      try {
        return from(
          instance.methods.requestLoan(
            [
              loan.id,
              netLoanInterestRateFormatted,
              loan.loanTerm,
              loanAmountFormatted,
              loanFeeFormatted,
              collateralAmountFormatted,
              loan.lenderAddress,
              loan.collateral.ethAddress,
              loan.underlying.ethAddress,
              insurerAddress
            ],
            loan.contractAddress,
            loan.signature,
            deadline
          )
            .send({
              from: this.userAddress,
            })
            .on('transactionHash', (hash) => {
              const toast = this.toastr.info(`0 seconds ago`, `Depositing to financing line!`, WEB3_TOASTR_CONFIG);
              tId = toast.toastId;
              timer(0, 1000).pipe(takeUntil(merge(toast.onHidden, this.destroy$))).subscribe(seconds => this.toastr.toasts.find(toast => toast.toastId == tId).toastRef.componentInstance.message = `${seconds} seconds ago`);
              return hash;
            })
            .on('receipt', (receipt) => {
              this.toastr.clear(tId);
              this.toastr.info('', 'Deposited successfully');
              return receipt;
            })
            .on('error', (error) => {
              this.toastr.clear(tId);
              return error;
            })
        ).pipe(takeUntil(this.destroy$))
      } catch (e) {
        return this.handleError(e, tId)
      }
    }))
  }


  // convertFloatToInteger
  currencyToWei(value: number, decimalPlaces: number): string {
    return new BigNumber(value).times(10 ** decimalPlaces).toFixed(0);
  }

  createStakingFif(fundType: string, tId = -1): Observable<any> {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const salt = provider.utils.randomHex(32);
      const hash = provider.utils.keccak256(fundType);
      const instance = new provider.eth.Contract(PLATFORM.abi, environment.web3.smartCreditContract);
      try {
        return from(instance.methods
          .createFixedIncomeFund(hash, salt, [0, 0, 0, 100])
          .send({ from: this.userAddress })
          .on('transactionHash', (hash) => {
            const toast = this.toastr.info(`0 seconds ago`, `Creating Staking Contract!`, WEB3_TOASTR_CONFIG);
            tId = toast.toastId;
            timer(0, 1000).pipe(takeUntil(merge(toast.onHidden, this.destroy$))).subscribe(seconds => this.toastr.toasts.find(toast => toast.toastId == tId).toastRef.componentInstance.message = `${seconds} seconds ago`);
            return hash;
          })
          .on('receipt', (receipt) => {
            this.toastr.clear(tId);
            this.toastr.info('You will be redirected to the Staking Dashboard. Please wait...', 'Staking contract created!');
            return receipt;
          })
          .on('error', (error) => {
            this.toastr.clear(tId);
            return error;
          })
        ).pipe(takeUntil(this.destroy$))
      } catch (e) {
        return this.handleError(e, tId)
      }
    }))
  }

  // **************** BORROWING *****************
  // Borrowing / Loan Details Page - ERC20 - approve repayment
  doERC20Approval(loan: Loan, tId = -1): Observable<any> {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const instance = new provider.eth.Contract(ERC20.abi, loan.underlying.ethAddress);
      try {
        return from(instance.methods
          .approve(loan.contractAddress, new BigNumber(loan.outstandingBalance).toNumber().toLocaleString('fullwide', { useGrouping: false }))
          .send({ from: this.userAddress })
          .on('transactionHash', (hash) => {
            const toast = this.toastr.info(`0 seconds ago`, `Approving Tokens!`, WEB3_TOASTR_CONFIG);
            tId = toast.toastId;
            timer(0, 1000).pipe(takeUntil(merge(toast.onHidden, this.destroy$))).subscribe(seconds => this.toastr.toasts.find(toast => toast.toastId == tId).toastRef.componentInstance.message = `${seconds} seconds ago`);
            return hash;
          })
          .on('receipt', (receipt) => receipt)
          .on('error', (error) => error)
        ).pipe(tap(() => this.toastr.clear(tId)), takeUntil(this.destroy$));
      } catch (e) {
        return this.handleError(e, tId);
      }
    }))
  }

  // Borrowing / Loan Details Page - BORROWING - repay ETH loan renamed from doETHRepayment
  repayEth(loan: Loan, tId = -1): Observable<any> {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const instance = new provider.eth.Contract(BORROWING.abi, loan.contractAddress);
      const insurerAddress = loan.insurerAddress;
      const netLoanInterestRateFormatted = parseFloat((loan.netLoanInterestRate * 100).toFixed(0));
      const loanAmountFormatted = loan.loanAmount.toLocaleString('fullwide', { useGrouping: false });
      const loanFeeFormatted = parseFloat(((loan.loanInsurancePremium + loan.platformFee + loan.partnerFee) * 100).toFixed(0));
      const collateralAmountFormatted = loan.collateralInfo.amount.toLocaleString('fullwide', { useGrouping: false })
      try {
        return from(instance.methods
          .repay([
            loan.id,
            netLoanInterestRateFormatted,
            loan.loanTerm,
            loanAmountFormatted,
            loanFeeFormatted,
            collateralAmountFormatted,
            loan.lenderAddress,
            loan.collateral.ethAddress,
            loan.underlying.ethAddress,
            insurerAddress
          ],
            loan.outstandingBalance
          ).send({ from: this.userAddress, value: loan.outstandingBalance })
          .on('transactionHash', (hash) => {
            const toast = this.toastr.info(`0 seconds ago`, `Repaying Loan!`, WEB3_TOASTR_CONFIG);
            tId = toast.toastId;
            timer(0, 1000).pipe(takeUntil(merge(toast.onHidden, this.destroy$))).subscribe(seconds => this.toastr.toasts.find(toast => toast.toastId == tId).toastRef.componentInstance.message = `${seconds} seconds ago`);
            return hash;
          })
          .on('receipt', (receipt) => receipt)
          .on('error', (error) => error)
        ).pipe(tap(() => this.toastr.clear(tId)), takeUntil(this.destroy$));
      } catch (e) {
        return this.handleError(e, tId);
      }
    }))
  }

  // Borrowing / Loan Details Page - BORROWING - repay ERC20 loan renamed from doERC20Repayment
  repayToken(loan: Loan, tId = -1): Observable<any> {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const instance = new provider.eth.Contract(BORROWING.abi, loan.contractAddress);
      const insurerAddress = loan.insurerAddress;
      const netLoanInterestRateFormatted = parseFloat((loan.netLoanInterestRate * 100).toFixed(0));
      const loanAmountFormatted = loan.loanAmount.toLocaleString('fullwide', { useGrouping: false });
      const loanFeeFormatted = parseFloat(((loan.loanInsurancePremium + loan.platformFee + loan.partnerFee) * 100).toFixed(0));
      const collateralAmountFormatted = loan.collateralInfo.amount.toLocaleString('fullwide', { useGrouping: false })
      try {
        return from(instance.methods
          .repay([
            loan.id,
            netLoanInterestRateFormatted,
            loan.loanTerm,
            loanAmountFormatted,
            loanFeeFormatted,
            collateralAmountFormatted,
            loan.lenderAddress,
            loan.collateral.ethAddress,
            loan.underlying.ethAddress,
            insurerAddress
          ],
            loan.outstandingBalance
          ).send({ from: this.userAddress })
          .on('transactionHash', (hash) => {
            const toast = this.toastr.info(`0 seconds ago`, `Repaying Tokens!`, WEB3_TOASTR_CONFIG);
            tId = toast.toastId;
            timer(0, 1000).pipe(takeUntil(merge(toast.onHidden, this.destroy$))).subscribe(seconds => this.toastr.toasts.find(toast => toast.toastId == tId).toastRef.componentInstance.message = `${seconds} seconds ago`);
            return hash;
          })
          .on('receipt', (receipt) => receipt)
          .on('error', (error) => error)
        ).pipe(tap(() => this.toastr.clear(tId)), takeUntil(this.destroy$));
      } catch (e) {
        return this.handleError(e, tId);
      }
    }))
  }

  // Credit Line/FIF - ERC20 - transfer ERC20 tokens to credit line / fif
  transferTokens(target: FixedIncomeFund | Loan, amountWei: any, isLoan?: boolean, tId = -1): Observable<any> {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const instance = new provider.eth.Contract(ERC20.abi, target.underlying.ethAddress);
      const amountFormatted = amountWei.toLocaleString('fullwide', { useGrouping: false });
      try {
        return from(instance.methods
          .transfer(target.contractAddress, amountFormatted)
          .send({ from: this.userAddress })
          .on('transactionHash', (hash) => {
            const toast = this.toastr.info(`0 seconds ago`, `Transferring Tokens!`, WEB3_TOASTR_CONFIG);
            tId = toast.toastId;
            timer(0, 1000).pipe(takeUntil(merge(toast.onHidden, this.destroy$))).subscribe(seconds => this.toastr.toasts.find(toast => toast.toastId == tId).toastRef.componentInstance.message = `${seconds} seconds ago`);
            return hash;
          })
          .on('receipt', (receipt) => {
            this.toastr.info(``, `Tokens transferred successfully!`);
            this.toastr.clear(tId);
            return receipt;
          })
          .on('error', (error) => {
            this.toastr.clear(tId);
            return error;
          })
        ).pipe(tap(() => this.toastr.clear(tId)), takeUntil(this.destroy$))
      } catch (e) {
        return this.handleError(e, tId);
      }
    }))
  }

  transferTokensToLoan(target: Loan, amountWei: any, tId = -1): Observable<any> {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const instance = new provider.eth.Contract(ERC20.abi, target.collateral.ethAddress);
      const amountFormatted = amountWei.toLocaleString('fullwide', { useGrouping: false });
      try {
        return from(instance.methods
          .transfer(target.contractAddress, amountFormatted)
          .send({ from: this.userAddress })
          .on('transactionHash', (hash) => {
            const toast = this.toastr.info(`0 seconds ago`, `Transferring tokens to Finance Line to make them available for this loan!`, WEB3_TOASTR_CONFIG);
            tId = toast.toastId;
            timer(0, 1000).pipe(takeUntil(merge(toast.onHidden, this.destroy$))).subscribe(seconds => this.toastr.toasts.find(toast => toast.toastId == tId).toastRef.componentInstance.message = `${seconds} seconds ago`);
            return hash;
          })
          .on('receipt', (receipt) => receipt)
          .on('error', (error) => error)
        ).pipe(tap(() => this.toastr.clear(tId)), takeUntil(this.destroy$));
      } catch (e) {
        return this.handleError(e, tId);
      }
    }))
  }

  transferEthereumToLoan(loan: Loan, amount: any, tId = -1): Observable<any> {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const amountWei = amount * 10 ** loan.collateral.decimalPlaces;
      const value = amountWei.toLocaleString('fullwide', { useGrouping: false });
      try {
        return from(provider.eth
          .sendTransaction({ from: this.userAddress, to: loan.contractAddress, value })
          .on('transactionHash', (hash) => {
            const toast = this.toastr.info(`0 seconds ago`, `Transferring ISLM to loan!`, WEB3_TOASTR_CONFIG);
            tId = toast.toastId;
            timer(0, 1000).pipe(takeUntil(merge(toast.onHidden, this.destroy$))).subscribe(seconds => this.toastr.toasts.find(toast => toast.toastId == tId).toastRef.componentInstance.message = `${seconds} seconds ago`);
            return hash;
          })
          .on('receipt', (receipt) => receipt)
          .on('error', (error) => error)
        ).pipe(tap(() => this.toastr.clear(tId)), takeUntil(this.destroy$));
      } catch (e) {
        return this.handleError(e, tId);
      }
    }))
  }

  //   // Credit Line/FIF - ERC20 - transfer ERC20 tokens to credit line / fif
  transferEthereum(target: FixedIncomeFund | Loan, amount: any, tId = -1): Observable<any> {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const amountWei = amount * 10 ** target.underlying.decimalPlaces;
      const value = amountWei.toLocaleString('fullwide', { useGrouping: false });
      try {
        return from(provider.eth
          .sendTransaction({ from: this.userAddress, to: target.contractAddress, value })
          .on('transactionHash', (hash) => {
            const toast = this.toastr.info(`0 seconds ago`, `Transferring ISLM!`, WEB3_TOASTR_CONFIG);
            tId = toast.toastId;
            timer(0, 1000).pipe(takeUntil(merge(toast.onHidden, this.destroy$))).subscribe(seconds => this.toastr.toasts.find(toast => toast.toastId == tId).toastRef.componentInstance.message = `${seconds} seconds ago`);
            return hash;
          })
          .on('receipt', (receipt) => receipt)
          .on('error', (error) => error)
        ).pipe(tap(() => this.toastr.clear(tId)), takeUntil(this.destroy$));
      } catch (e) {
        return this.handleError(e, tId);
      }
    }))
  }

  transferTokensToCreditLine(target: CreditLine, currency: Currency, amount: any, tId = -1) {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const instance = new provider.eth.Contract(ERC20.abi, currency.ethAddress);
      try {
        return from(instance.methods
          .transfer(target.contractAddress, amount)
          .send({ from: this.userAddress })
          .on('transactionHash', (hash) => {
            const toast: ActiveToast<any> = this.toastr.info(`0 seconds ago`, `Transferring ${currency.symbol} to Finance Line!`, WEB3_TOASTR_CONFIG)
            tId = toast.toastId;
            timer(0, 1000).pipe(takeUntil(merge(toast.onHidden, this.destroy$))).subscribe(seconds => { this.toastr.toasts.find(toast => toast.toastId == tId).toastRef.componentInstance.message = `${seconds} seconds ago` });
            return hash;
          })
          .on('receipt', (receipt) => receipt)
          .on('error', (error) => error)
        ).pipe(tap(() => this.toastr.clear(tId)), takeUntil(this.destroy$));
      } catch (e) {
        return this.handleError(e, tId);
      }
    }))
  }

  // transferEthereumToCreditLine(target: CreditLine, currency: Currency, amount: any, tId = -1): Observable<any> {
  //   return this.getProvider().pipe(concatMap((provider: any) => {
  //     try {

  //       const ethersProvider = new ethers.providers.Web3Provider(provider);
  //       const signer = ethersProvider.getSigner();
  //       return from(provider.eth
  //         .sendTransaction({ from: this.userAddress, to: target.contractAddress, value: amount })
  //         .on('transactionHash', (hash) => {
  //           const toast = this.toastr.info(`0 seconds ago`, `Transferring ISLM to Finance Line!`, WEB3_TOASTR_CONFIG);
  //           tId = toast.toastId;
  //           timer(0, 1000).pipe(takeUntil(merge(toast.onHidden, this.destroy$))).subscribe(seconds => this.toastr.toasts.find(toast => toast.toastId == tId).toastRef.componentInstance.message = `${seconds} seconds ago`);
  //           return hash;
  //         })
  //         .on('receipt', (receipt) => receipt)
  //         .on('error', (error) => error)
  //       ).pipe(tap(() => this.toastr.clear(tId)), takeUntil(this.destroy$));
  //     } catch (e) {
  //       return this.handleError(e, tId);
  //     }
  //   }))
  // }

  transferEthereumToCreditLine(target: CreditLine, currency: Currency, amount: any, tId = -1): Observable<any> {
    return this.getProvider().pipe(
      concatMap((provider: Web3) => {
        try {
          // Directly using Web3.js's `sendTransaction`
          return from(
            provider.eth.sendTransaction({
              from: this.userAddress,
              to: target.contractAddress,
              value: amount
            }).then((tx) => {
              const toast = this.toastr.info('0 seconds ago', 'Transferring ISLM to Finance Line!', WEB3_TOASTR_CONFIG);
              tId = toast.toastId;
              timer(0, 1000).pipe(takeUntil(merge(toast.onHidden, this.destroy$))).subscribe(seconds => {
                const currentToast = this.toastr.toasts.find(t => t.toastId === tId);
                if (currentToast) {
                  currentToast.toastRef.componentInstance.message = `${seconds} seconds ago`;
                }
              });
              return tx;
            })
          ).pipe(
            tap(() => this.toastr.clear(tId)),
            takeUntil(this.destroy$)
          );
        } catch (error) {
          return this.handleError(error, tId);  // handleError should handle any issues here
        }
      })
    );
  }


  // Borrowing - Loan Details - BORROWING - increase loan collateral
  increaseLoanCollateral(loan: Loan, amount: string, tId = -1): Observable<any> {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const instance = new provider.eth.Contract(BORROWING.abi, loan.contractAddress);
      const insurerAddress = loan.insurerAddress;
      const netLoanInterestRateFormatted = parseFloat((loan.netLoanInterestRate * 100).toFixed(0));
      const loanAmountFormatted = loan.loanAmount.toLocaleString('fullwide', { useGrouping: false });
      const loanFeeFormatted = parseFloat(((loan.loanInsurancePremium + loan.platformFee + (loan.partnerFee || 0)) * 100).toFixed(0));
      const collateralAmountFormatted = loan.collateralInfo.amount.toLocaleString('fullwide', { useGrouping: false });
      try {
        return from(instance.methods.increaseCollateral([
          loan.id,
          netLoanInterestRateFormatted,
          loan.loanTerm,
          loanAmountFormatted,
          loanFeeFormatted,
          collateralAmountFormatted,
          loan.lenderAddress,
          loan.collateral.ethAddress,
          loan.underlying.ethAddress,
          insurerAddress
        ],
          amount
        ).send({ from: this.userAddress })
          .on('transactionHash', (hash) => {
            const toast = this.toastr.info(`0 seconds ago`, `Increasing Collateral!`, WEB3_TOASTR_CONFIG);
            tId = toast.toastId;
            timer(0, 1000).pipe(takeUntil(merge(toast.onHidden, this.destroy$)))
              .subscribe(seconds => this.toastr.toasts.find(toast => toast.toastId == tId).toastRef.componentInstance.message = `${seconds} seconds ago`);
            return hash;
          })
          .on('receipt', (receipt) => receipt)
          .on('error', (error) => error)
        ).pipe(tap(() => this.toastr.clear(tId)), takeUntil(this.destroy$));
      } catch (e) {
        return this.handleError(e, tId);
      }
    }))
  }

  // Borrowing - Credit Line Details - BORROWING - Withdraw available credit line assets
  withdrawFromCreditLine(creditLine: CreditLine, currency: Currency, amount: any, tId = -1): Observable<any> {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const instance = new provider.eth.Contract(BORROWING.abi, creditLine.contractAddress);
      try {
        return from(instance.methods
          .withdrawAsset(currency.ethAddress, amount)
          .send({ from: this.userAddress })
          .on('transactionHash', (hash) => {
            const toast = this.toastr.info(`0 seconds ago`, `Withdrawing From Finance Line!`, WEB3_TOASTR_CONFIG);
            tId = toast.toastId;
            timer(0, 1000).pipe(takeUntil(merge(toast.onHidden, this.destroy$)))
              .subscribe(seconds => this.toastr.toasts.find(toast => toast.toastId == tId).toastRef.componentInstance.message = `${seconds} seconds ago`);
            return hash;
          })
          .on('receipt', (receipt) => receipt)
          .on('error', (error) => error)
        ).pipe(tap(() => this.toastr.clear(tId)), takeUntil(this.destroy$));
      } catch (e) {
        return this.handleError(e, tId);
      }
    }))
  }

  // Borrowing - Loan Details - BORROWING - Get loan data
  getLoanData(loanContractAddress, loanId): Observable<any> {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const instance = new provider.eth.Contract(BORROWING.abi, loanContractAddress);
      try {
        return from(instance.methods.getLoanData(loanId).call()).pipe(takeUntil(this.destroy$));
      } catch (e) {
        if (!environment.production) console.log(e);
        return EMPTY;
      }
    }))
  }

  // Borrowing - Detail Credit Line - BORROWING - get specific credit line locked asset value
  lockedAssetValue(assetAddress, creditLineAddress): Observable<any> {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const instance = new provider.eth.Contract(BORROWING.abi, creditLineAddress);
      try {
        return from(instance.methods.getLockedAssetValue(assetAddress).call()).pipe(takeUntil(this.destroy$));
      } catch (e) {
        if (!environment.production) console.log(e);
        return EMPTY;
      }
    }))
  }

  // Borrowing - Detail Loan / Detail Credit Line / Loan Dashboard - BORROWING - get specific credit line unlocked assets
  unlockedAssetValue(assetAddress, creditLineAddress): Observable<any> {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const instance = new provider.eth.Contract(BORROWING.abi, creditLineAddress);
      try {
        return from(instance.methods.getUnlockedAssetValue(assetAddress).call()).pipe(takeUntil(this.destroy$));
      } catch (e) {
        if (!environment.production) console.log(e);
        return EMPTY;
      }
    }))
  }

  // ************ FIXED INCOME FUNDS ************
  // Lending - FIF Details - LENDING - Withdraw available FIF assets
  withdrawFixedIncomeFund(fixedIncomeFundAddress, amount, tId = -1): Observable<any> {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const instance = new provider.eth.Contract(LENDING.abi, fixedIncomeFundAddress);
      try {
        return from(instance.methods
          .withdraw(amount)
          .send({ from: this.userAddress })
          .on('transactionHash', (hash) => {
            const toast = this.toastr.info(`0 seconds ago`, `Withdrawing from Fixed Income Fund!`, WEB3_TOASTR_CONFIG);
            tId = toast.toastId;
            timer(0, 1000).pipe(takeUntil(merge(toast.onHidden, this.destroy$))).subscribe(seconds => this.toastr.toasts.find(toast => toast.toastId == tId).toastRef.componentInstance.message = `${seconds} seconds ago`);
            return hash;
          })
          .on('receipt', (receipt) => receipt)
          .on('error', (error) => error)
        ).pipe(tap(() => this.toastr.clear(tId)), takeUntil(this.destroy$));
      } catch (e) {
        return this.handleError(e, tId);
      }
    }))
  }

  // Lending - FIF Details - LENDING - Terminate FIF
  terminateFixedIncomeFund(fixedIncomeFundAddress: string, tId = -1): Observable<any> {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const instance = new provider.eth.Contract(LENDING.abi, fixedIncomeFundAddress);
      try {
        return from(instance.methods.terminate().send({ from: this.userAddress })
          .on('transactionHash', (hash) => {
            const toast = this.toastr.info(`0 seconds ago`, `Terminating Fixed Income Fund!`, WEB3_TOASTR_CONFIG);
            tId = toast.toastId;
            timer(0, 1000).pipe(takeUntil(merge(toast.onHidden, this.destroy$))).subscribe(seconds => this.toastr.toasts.find(toast => toast.toastId == tId).toastRef.componentInstance.message = `${seconds} seconds ago`);
            return hash;
          })
          .on('receipt', (receipt) => receipt)
          .on('error', (error) => error)
        ).pipe(tap(() => this.toastr.clear(tId)), takeUntil(this.destroy$));
      } catch (e) {
        return this.handleError(e, tId);
      }
    }))
  }

  // Lending - FIF Details - LENDING - Change bucket ratio
  updateBucketRatio(fixedIncomeFundAddress: string, buckets: FifBucket[], tId = -1): Observable<any> {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const instance = new provider.eth.Contract(LENDING.abi, fixedIncomeFundAddress);
      try {
        return from(instance.methods
          .updateBucketRatio([buckets[0].investmentRatio, buckets[1].investmentRatio, buckets[2].investmentRatio, buckets[3].investmentRatio])
          .send({ from: this.userAddress })
          .on('transactionHash', (hash) => {
            const toast = this.toastr.info(`0 seconds ago`, `Reallocating fund distributions!`, WEB3_TOASTR_CONFIG);
            tId = toast.toastId;
            timer(0, 1000).pipe(takeUntil(merge(toast.onHidden, this.destroy$))).subscribe(seconds => this.toastr.toasts.find(toast => toast.toastId == tId).toastRef.componentInstance.message = `${seconds} seconds ago`);
            return hash;
          })
          .on('receipt', (receipt) => {
            this.toastr.clear(tId);
            return receipt;
          })
          .on('error', (error) => {
            this.toastr.clear(tId);
            return throwError(() => error);
          })
        ).pipe(catchError((err: any): Observable<any> => {
          if (!environment.production) console.log(err);
          return new Observable(undefined);
        }), takeUntil(this.destroy$));
      } catch (e) {
        return this.handleError(e, tId);
      }
    }))
  }

  // Lending - FIF Details - LENDING - get amount actively invested - renamed from getTotalInvested()
  getActivelyInvested(fixedIncomeFundAddress): Observable<any> {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const instance = new provider.eth.Contract(LENDING.abi, fixedIncomeFundAddress);
      try {
        return from(instance.methods.totalFundInvested().call())

      } catch (e) {
        if (!environment.production) console.log(e);
        return EMPTY;
      }
    }))
  }

  getCompoundSupplyRate(currency: Currency, compoundInvestmentRatio: number): any {
    let apy1 = 0;
    if (compoundInvestmentRatio > 0 && this.isBrowser) {
      let provider = this.userAddress ? this.provider : new Web3(new Web3.providers.HttpProvider("https://ethereum.publicnode.com12e1"));
      let fallbackProvider = this.userAddress ? this.provider : new Web3(new Web3.providers.HttpProvider("https://cloudflare-eth.com"));
      const ethMantissa = 1e18;
      const blocksPerDay = 7200; // 12 seconds per block
      const daysPerYear = 365;
      const instance = new provider.eth.Contract(COMPOUND_V2[`c${currency.symbol}`], cTokens[`c${currency.symbol}`]);
      const fallbackInstance = new fallbackProvider.eth.Contract(COMPOUND_V2[`c${currency.symbol}`], cTokens[`c${currency.symbol}`])
      return from(instance.methods.supplyRatePerBlock().call())
        .pipe(
          take(1),
          catchError((err: any): Observable<any> => {
            return from(fallbackInstance.methods.supplyRatePerBlock().call())
          }),
          map((supplyRatePerBlock: any) => {
            const supplyApy = (((Math.pow((supplyRatePerBlock / ethMantissa * blocksPerDay) + 1, daysPerYear))) - 1) * 100;
            return supplyApy;
          }))
    }
    else {
      return of(apy1);
    }
  }

  fetchEthBalance(contractAddress: string): Observable<any> {
    return this.getProvider().pipe(concatMap((provider: any) => {
      return from(provider.eth.getBalance(contractAddress))
    }))
  }

  fetchBalance(tokenAddress, walletAddress, currencySymbol): Observable<any> {
    return this.getProvider().pipe(concatMap((provider: any) => {
      if (tokenAddress !== '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE') {
        const instance = new provider.eth.Contract(ERC20.abi, tokenAddress);
        try {
          return from(instance.methods.balanceOf(walletAddress).call())
        } catch (e) {
          if (!environment.production) console.log(e);
          return EMPTY;
        }
      } else {
        return from(provider.eth.getBalance(walletAddress))
      }
    }))
  }

  // *--------------- REWARDS ----------------
  // Rewards - REWARDS - Redeem rewards to wallet
  redeemRewards(claimedReward: ClaimedReward, tId = -1) {
    return this.getProvider().pipe(concatMap((provider: any) => {
      claimedReward = {
        ...claimedReward,
        rewards: claimedReward.rewards.map((reward: Reward) => ({
          ...reward,
          Currency: reward.Currency ? reward.Currency : reward.currency
        }))
      }
      let tokenValues: any[] = [];
      let tokenAddresses: any[] = [];
      let ethValue = '0';
      claimedReward.rewards.forEach((reward: Reward) => {
        if (reward.Currency.symbol !== 'ISLM') {
          if (tokenAddresses.includes(reward.Currency.ethAddress)) {
            tokenValues[tokenAddresses.indexOf(reward.Currency.ethAddress)] = new BigNumber(tokenValues[tokenAddresses.indexOf(reward.Currency.ethAddress)]).plus(reward.weiValue).toNumber().toLocaleString('fullwide', { useGrouping: false })
          } else {
            tokenValues.push(new BigNumber(reward.weiValue).toNumber().toLocaleString('fullwide', { useGrouping: false }));
            tokenAddresses.push(reward.Currency.ethAddress);
          }
        } else {
          ethValue = new BigNumber(ethValue).plus(reward.weiValue).toNumber().toLocaleString('fullwide', { useGrouping: false });
        }
      });
      const instance = new provider.eth.Contract(REWARDS.abi, environment.web3.rewardsContract);
      try {
        return from(instance.methods
          .redeem(claimedReward.signature, claimedReward.id, claimedReward.receiver, tokenValues, tokenAddresses, ethValue)
          .send({ from: this.userAddress })
          .on('transactionHash', (hash) => {
            const toast = this.toastr.info(`0 seconds ago`, `Redeeming rewards to your wallet!`, WEB3_TOASTR_CONFIG);
            tId = toast.toastId;
            timer(0, 1000).pipe(takeUntil(merge(toast.onHidden, this.destroy$))).subscribe(seconds => this.toastr.toasts.find(toast => toast.toastId == tId).toastRef.componentInstance.message = `${seconds} seconds ago`);
            return hash;
          })
          .on('receipt', (receipt) => receipt)
          .on('error', (error) => error)
        ).pipe(tap(() => this.toastr.clear(tId)), takeUntil(this.destroy$));
      } catch (e) {
        return this.handleError(e, tId);
      }
    }))
  }

  // *************** STAKING ***************
  // Staking - Staking Details - STAKING - Start the cooldown - renamed from startStakingCooldown()
  startCooldown(stakingContractAddress: string, tId = -1): Observable<any> {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const instance = new provider.eth.Contract(STAKING.abi, stakingContractAddress);
      try {
        return from(instance.methods
          .startCooldown()
          .send({ from: this.userAddress })
          .on('transactionHash', (hash) => {
            const toast = this.toastr.info(`0 seconds ago`, `Starting staking withdrawal cooldown!`, WEB3_TOASTR_CONFIG);
            tId = toast.toastId;
            timer(0, 1000).pipe(takeUntil(merge(toast.onHidden, this.destroy$))).subscribe(seconds => this.toastr.toasts.find(toast => toast.toastId == tId).toastRef.componentInstance.message = `${seconds} seconds ago`);
            return hash;
          })
          .on('receipt', (receipt) => receipt)
          .on('error', (error) => error)
        ).pipe(tap(() => this.toastr.clear(tId)), takeUntil(this.destroy$));
      } catch (e) {
        return this.handleError(e, tId);
      }
    }))
  }

  // Staking - Staking Details - STAKING - get current cooldown in milliseconds - renamed from getCooldown()
  getCooldownMs(stakingContractAddress: string): Observable<any> {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const instance = new provider.eth.Contract(STAKING.abi, stakingContractAddress);
      try {
        return from(instance.methods.stakingCooldown().call()).pipe(takeUntil(this.destroy$));
      } catch (e) {
        if (!environment.production) console.log(e);
        return EMPTY;
      }
    }))
  }

  // Staking - Staking Details - STAKING - widthdraw staked amount - renamed from withdrawStakedAmount()
  withdrawStaking(amount: string, stakingFund: FixedIncomeFund, tId = -1): Observable<any> {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const instance = new provider.eth.Contract(STAKING.abi, stakingFund.contractAddress);
      try {
        return from(instance.methods
          .withdraw(amount)
          .send({ from: this.userAddress })
          .on('transactionHash', (hash) => {
            const toast = this.toastr.info(`0 seconds ago`, `Withdrawing from staking contract!`, WEB3_TOASTR_CONFIG);
            tId = toast.toastId;
            timer(0, 1000).pipe(takeUntil(merge(toast.onHidden, this.destroy$))).subscribe(seconds => this.toastr.toasts.find(toast => toast.toastId == tId).toastRef.componentInstance.message = `${seconds} seconds ago`);
            return hash;
          })
          .on('receipt', (receipt) => receipt)
          .on('error', (error) => error)
        ).pipe(tap(() => this.toastr.clear(tId)), takeUntil(this.destroy$));
      } catch (e) {
        return this.handleError(e, tId);
      }
    }))
  }

  // Staking - Staking Details - STAKING - deposit to staking - renamed from depositToStakingFif()
  depositStaking(amount: any, stakingFund: FixedIncomeFund, tId = -1): Observable<any> {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const instance = new provider.eth.Contract(STAKING.abi, stakingFund.underlying.ethAddress);
      const amountFormatted = parseFloat(amount).toLocaleString('fullwide', { useGrouping: false });
      try {
        return from(instance.methods
          .transfer(stakingFund.contractAddress, amountFormatted)
          .send({ from: this.userAddress })
          .on('transactionHash', (hash) => {
            const toast = this.toastr.info(`0 seconds ago`, `Depositing to staking contract!`, WEB3_TOASTR_CONFIG);
            tId = toast.toastId;
            timer(0, 1000).pipe(takeUntil(merge(toast.onHidden, this.destroy$))).subscribe(seconds => this.toastr.toasts.find(toast => toast.toastId == tId).toastRef.componentInstance.message = `${seconds} seconds ago`);
            return hash;
          })
          .on('receipt', (receipt) => receipt)
          .on('error', (error) => error)
        ).pipe(tap(() => this.toastr.clear(tId)), takeUntil(this.destroy$));
      } catch (e) {
        return this.handleError(e, tId);
      }
    }))
  }

  // ****************** ERC20 ******************
  // Staking - Staking Details - STAKING - Check current cooldown status - renamed from checkCooldown()
  getCooldownStatus(stakingContractAddress: string): Observable<any> {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const instance = new provider.eth.Contract(STAKING.abi, stakingContractAddress);
      try {
        return from(instance.methods.checkCooldownEnabled().call());
      } catch (e) {
        if (!environment.production) console.log(e);
        return EMPTY;
      }
    }))
  }

  // Borrowing - ERC20 - get allowance for a specific token- renamed from fetchTokenAllowance
  getTokenAllowance(underlying: Currency, contractAddress: string): Observable<any> {
    return this.getProvider().pipe(concatMap((provider: any) => {
      if (underlying.symbol !== 'ISLM') {
        const instance = new provider.eth.Contract(ERC20.abi, underlying.ethAddress);
        try {
          return from(instance.methods.allowance(this.userAddress, contractAddress).call()).pipe(takeUntil(this.destroy$))
        } catch (e) {
          if (!environment.production) console.log(e);
          return EMPTY;
        }
      }
      return of('9999999999999999999999999').pipe(takeUntil(this.destroy$));
    }))
  }

  // get token balance from smartcontract
  getTokenBalance(tokenAddress: string, walletAddress: string): Observable<any> {
    return this.getProvider().pipe(concatMap((provider: any) => {
      const instance = new provider.eth.Contract(ERC20.abi, tokenAddress);
      try {
        return from(instance.methods.balanceOf(walletAddress).call()).pipe(takeUntil(this.destroy$));
      } catch (e) {
        if (!environment.production) console.log(e);
        return EMPTY;
      }
    }))
  }

  randomHex(): string {
    return this.provider ? this.provider.utils.randomHex(32) : undefined;
  }

  handleError(e: Error, tId?: number) {
    if (tId) this.toastr.clear(tId);
    if (!environment.production) console.log(e);
    return EMPTY;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
