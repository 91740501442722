import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FixedIncomeFund } from 'src/app/shared/interfaces/fifs/fixedIncomeFund';

@Component({
  selector: 'fifs-table',
  templateUrl: './fifs-table.component.html',
  styleUrls: []
})
export class FifsTableComponent implements OnInit {
  @Input() cryptoIcons: {};
  @Input() fixedIncomeFunds: FixedIncomeFund[]

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  goToLoan(fund: FixedIncomeFund) {
    // angular router navigate to lend/fif with param fifId
    this.router.navigate(['sell/fif', fund.id]);
  }

  calculateTotalInvested(fund: any): number {
    const activeInvested = parseFloat(fund?.activeInvested || '0');
    const availableFunds = parseFloat(fund?.availableFunds || '0');
    return activeInvested + availableFunds;
  }
}
