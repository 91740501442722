import { MENU_ICONS } from 'src/assets/images/svg-icons';
import { MenuItem } from '../app/shared/interfaces/menu/menu-item';

export const MAIN_MENU: Array<MenuItem> = [{
  group: true,
  items: [{
    title: 'Purchase',
    routerLink: '/purchase/dashboard',
    icon: MENU_ICONS.borrow,
    authentication: false,
    alternateLink: '/purchase/dashboard',
  }, {
    title: 'Sell',
    routerLink: '/sell/dashboard',
    icon: MENU_ICONS.lend,
    authentication: false,
    alternateLink: '/sell/dashboard',
  },
    // {
    //   title: 'Stake',
    //   group: true,
    //   icon: MENU_ICONS.stake,
    //   authentication: false,
    //   badge: 'NEW',
    //   items: [{
    //     title: 'Leveraged Staking',
    //     routerLink: '/leveraged-staking',
    //     authentication: false,
    //     alternateLink: '/leveraged-staking',
    //     badge: 'NEW!'
    //   }, {
    //     title: 'Stake SMARTCREDIT Tokens',
    //     routerLink: '/stake',
    //     authentication: false,
    //     alternateLink: '/stake'
    //   }]
    // },
    // {
    //   title: 'Earn',
    //   routerLink: '/earn',
    //   icon: MENU_ICONS.earn,
    //   authentication: false,
    //   alternateLink: '/earn',
    // }, 
    // {
    //   title: 'AI',
    //   group: true,
    //   icon: MENU_ICONS.ai,
    //   authentication: false,
    //   items: [{
    //     title: 'ChainAware Wallet Auditor',
    //     link: 'https://chainaware.ai/',
    //     icon: MENU_ICONS.st,
    //     blank: true,
    //     index: 0
    //   },
    //    {
    //     title: 'ChainAware Credit Score',
    //     link: 'https://chainaware.ai/credit-score-reports/',
    //     icon: MENU_ICONS.st,
    //     blank: true,
    //     index: 1
    //   }, {
    //     title: 'ChainAware User Segmentation',
    //     link: 'https://chainaware.ai/solutions/user-segmentation/',
    //     icon: MENU_ICONS.st,
    //     blank: true,
    //     index: 2
    //   }, {
    //     title: 'ChainAware Telegram Bot',
    //     link: 'https://chainaware.ai/chainaware-ai-bot/',
    //     icon: MENU_ICONS.st,
    //     blank: true,
    //     index: 3,
    //     badge: 'NEW'
    //   }
    // ]}
  ],
}];

export const SECONDARY_MENU: Array<MenuItem> = [{
  group: true,
  items: [
  //   {
  //   title: 'Learn',
  //   link: 'https://learn.barakafi.com',
  //   blank: true,
  //   index: 0
  // },
   {
    title: 'Company',
    group: true,
    index: 1,
    items: [{
      title: 'About Us',
      link: 'https://barakafi.com/about',
      blank: true,
      index: 0
    }, {
      title: 'Contact Us',
      routerLink: '/support',
      blank: false,
      index: 1
    }, {
      title: 'Blog',
      link: 'https://barakafi.com/blog',
      blank: true,
      index: 2
    }, 
    // {
    //   title: 'Learn',
    //   link: 'https://learn.barakafi.com',
    //   blank: true,
    //   index: 3
    // }, 
    // {
    //   title: 'Partners',
    //   link: 'https://barakafi.com/partners',
    //   blank: true,
    //   index: 3
    // }, {
    //   title: 'SMARTCREDIT Token',
    //   link: 'https://app.1inch.io/#/1/simple/swap/USDT/SMARTCREDIT',
    //   blank: true,
    //   index: 4
    // }
  ]
  }, 
  // {
  //   title: 'Exchange',
  //   index: 2
  // }
]
}]

export const MOBILE_MENU: Array<MenuItem> = [{
  group: true,
  items: [{
    title: 'Purchase',
    routerLink: '/purchase',
    icon: MENU_ICONS.borrow,
    authentication: false,
    alternateLink: '/purchase/dashboard',
  }, {
    title: 'Sell',
    routerLink: '/sell',
    icon: MENU_ICONS.lend,
    authentication: false,
    alternateLink: '/sell/dashboard',
  },
  //  {
  //   title: 'Stake',
  //   group: true,
  //   icon: MENU_ICONS.stake,
  //   authentication: false,
  //   items: [{
  //     title: 'Leveraged Staking',
  //     routerLink: '/leveraged-staking',
  //     authentication: false,
  //     alternateLink: '/leveraged-staking',
  //     badge: 'NEW!'
  //   }, {
  //     title: 'Stake SMARTCREDIT Tokens',
  //     routerLink: '/stake',
  //     authentication: false,
  //     alternateLink: '/stake'
  //   }]
  // }, {
  //   title: 'Earn',
  //   routerLink: '/earn',
  //   icon: MENU_ICONS.earn,
  //   authentication: false,
  //   alternateLink: '/earn',
  // }, {
  //   title: 'AI',
  //   group: true,
  //   icon: MENU_ICONS.ai,
  //   authentication: false,
  //   items: [{
  //     title: 'ChainAware Wallet Auditor',
  //     link: 'https://chainaware.ai/',
  //     blank: true,
  //     index: 0
  //   }, {
  //     title: 'ChainAware Credit Score',
  //     link: 'https://chainaware.ai/credit-score-reports/',
  //     blank: true,
  //     index: 1
  //   }, {
  //     title: 'ChainAware User Segmentation',
  //     link: 'https://chainaware.ai/solutions/user-segmentation/',
  //     blank: true,
  //     index: 2
  //   }, {
  //     title: 'ChainAware Telegram Bot',
  //     link: 'https://chainaware.ai/chainaware-ai-bot/',
  //     blank: true,
  //     index: 3,
  //     badge: 'NEW'
  //   }]}, 
  {
    group: true,
    title: 'More',
    icon: MENU_ICONS.menu,
    authentication: false,
    items: [
    //   {
    //   title: 'Exchange',
    //   index: 0
    // }, 
    {
      title: 'About Us',
      link: 'https://barakafi.com/about',
      blank: true,
      index: 2
    }, {
      title: 'Contact Us',
      routerLink: '/support',
      blank: false,
      index: 3
    }, {
      title: 'Blog',
      link: 'https://barakafi.com/blog',
      blank: true,
      index: 4
    },
    //  {
    //   title: 'Partners',
    //   link: 'https://barakafi.com/partners',
    //   blank: true,
    //   index: 5
    // }, {
    //   title: 'SMARTCREDIT Token',
    //   link: 'https://app.1inch.io/#/1/simple/swap/USDT/SMARTCREDIT',
    //   blank: true,
    //   index: 6
    // },
    //  {
    //   title: 'Learn',
    //   link: 'https://learn.smartcredit.io',
    //   blank: true,
    //   index: 7
    // }
  ]
  }]
}]