<shared-title title="Financing Dashboard">
  <button class="primary mb-3" [routerLink]="['/purchase']">
    <sc-icon
      [src]="icons.add"
      [size]="'0.5'"
      [width]="'1.5rem'"
      class="mr-2"
    ></sc-icon>
    New Purchase
  </button>
</shared-title>
<!-- loading screen -->
<div
  *ngIf="isFetching"
  class="mx-auto text-center rounded-3xl bg-slate-50 py-24 mb-8"
>
  <!-- loading spinner -->
  <spinner size="sm"></spinner>
  <div class="text-md text-slate-400 font-medium px-4">
    Loading Dashboard...
  </div>
</div>

<div
  *ngIf="!this.user || !this.user.ethAddress"
  class="mx-auto text-center rounded-3xl bg-slate-50 py-24 mb-8"
>
  <sc-icon
    [size]="'2rem'"
    [src]="icons.locked"
    class="mx-auto"
    class="inline-block"
  >
  </sc-icon>
  <div class="text-md text-slate-400 font-medium px-4">
    You must log in to view the financing dashboard!
  </div>
  <div class="mt-6">
    <shared-account-center></shared-account-center>
  </div>
</div>

<section
  *ngIf="this.user && this.user.ethAddress && borrowingStats && !isFetching"
  class="card flex flex-col md:flex-row w-full mb-8"
>
  <div class="w-full md:w-2/6 border-b-2 md:border-r-2 md:border-b-0">
    <div class="mb-4">
      <div class="text-[#5e7eab] font-medium">My Stats</div>
      <h2 class="text-slate-700 mb-0">My Active Financing</h2>
      <div class="text-2xl font-bold text-sc-primary">
        {{ borrowingStats.totalLoans || 0 }}
      </div>
    </div>
    <div class="mb-4">
      <div class="text-[#5e7eab]">Currently Financed</div>
      <div class="text-[#213b66] font-medium">
        {{
          borrowingStats.currentlyBorrowed || 0
            | currency : "USD" : "symbol-narrow" : "1.0-0"
        }}
      </div>
    </div>
    <div class="mb-4">
      <div class="text-[#5e7eab]">Collateral Deposited</div>
      <div class="text-[#213b66] font-medium">
        {{
          borrowingStats.collateralDeposited || 0
            | currency : "USD" : "symbol-narrow" : "1.0-0"
        }}
      </div>
    </div>
  </div>
  <div
    class="flex flex-col justify-between w-full md:w-4/6 md:pl-8 pb-4 mt-4 md:mt-0"
  >
    <div class="mb-4 flex items-top justify-between">
      <div>
        <div class="text-[#5e7eab] font-medium">Platform Stats</div>
        <h2 class="text-slate-700 mb-0">
          Financed on Baraka<span class="text-sc-primary text-xl">Fi</span>
        </h2>
        <div class="text-2xl font-bold text-sc-primary">
          {{
            borrowingStats.platformBorrowed || 0
              | currency : "USD" : "symbol-narrow" : "1.0-0"
          }}
        </div>
      </div>
      <!-- <a href="https://dune.com/smartcredit/smartcredit" target="_blank">
        <button class="default sm flex items-center">
          <div class="text-[#5e7eab] font-medium text-sm">More Stats</div>
          <sc-icon [src]="icons.external" [size]="'0.3'" [width]="'1.2rem'"></sc-icon>
        </button>
      </a> -->
    </div>
    <div class="flex gap-6">
      <div
        class="flex w-full md:h-20 flex-col justify-between bg-[#EEF5FF] text-[#213b66] rounded-2xl px-4 py-2 shadow font-medium"
      >
        <div class="text-xl font-semibold truncate">
          {{
            (stats && stats.borrowingStatistics
              ? stats.borrowingStatistics.totalLockedCollateralVolumeInUSD || 0
              : 0
            ) | currency : "USD" : "symbol-narrow" : "1.0-0"
          }}
        </div>
        <div class="text-[#5e7eab]">Collateral Locked</div>
      </div>
      <div
        class="flex w-full md:h-20 flex-col justify-between bg-[#EEF5FF] text-[#213b66] rounded-2xl px-4 py-2 shadow font-medium"
      >
        <div class="text-xl font-semibold truncate">
          {{
            stats && stats.borrowerLenderWeeklyRewards
              ? stats?.borrowerLenderWeeklyRewards?.borrowerRewards || 0
              : (0 | number : "1.2-2")
          }}
          {{ stats?.rewardConfigData?.rewardToken }}
        </div>
        <div class="text-[#5e7eab]">Borrower Rewards (Last Week)</div>
      </div>
    </div>
  </div>
</section>

<section
  *ngIf="
    this.user &&
    this.user.ethAddress &&
    creditLines &&
    creditLines.length > 0 &&
    !isFetching
  "
>
  <h2>Financing Lines</h2>
  <div class="flex flex-col md:flex-row gap-6">
    <div
      *ngFor="let creditLine of creditLines"
      class="card flex flex-col w-full lg:w-1/3 md:w-1/2 hover:bg-slate-50 cursor-pointer"
      [routerLink]="['../credit-line/', creditLine.contractAddress]"
    >
      <div class="flex flex-row my-2">
        <h3 class="self-center my-0 mr-2">
          {{ creditLine.type | creditLineType }}
        </h3>
        <span
          *ngIf="creditLine.loans && creditLine.loans.length > 0"
          class="badge primary-inverse self-center"
        >
          Active
        </span>
      </div>
      <div class="text-sm font-normal mb-1">
        {{ creditLine.type | creditLineTypeText }}
      </div>
      <p *ngIf="creditLine.collaterals" class="text-sm font-normal text-slate-500 mb-1">Assets</p>
      <div *ngIf="creditLine.underlyingStats" class="flex currencies items-center flex-wrap ml-2 mb-2">
        <ng-container *ngFor="let asset of creditLine.underlyingStats; let i = index">
          <sc-icon *ngIf="i < maxCurrenciesToShow" [src]="icons[asset.currency.symbol]" width="32px"
            title="{{ asset.currency.symbol }}" height="32px" [ngStyle]="{'margin-left': '-10px' }"
            class="bg-white rounded-full border-2 border-white"></sc-icon>
          <div *ngIf="i === 9" class="text-sm font-normal text-slate-500 ml-1">+{{
            remainingCollaterals }} more
          </div>
        </ng-container>
      </div>
      <p
        *ngIf="creditLine.collateralStats"
        class="text-sm font-normal text-slate-500 mb-1"
      >
        Collaterals
      </p>
      <div
        *ngIf="creditLine.collateralStats"
        class="flex currencies items-center flex-wrap ml-2 mb-2"
      >
        <ng-container
          *ngFor="let collateral of creditLine.collateralStats; let i = index"
        >
          <sc-icon
            *ngIf="i < maxCurrenciesToShow"
            [src]="icons[collateral.currency.symbol]"
            width="32px"
            title="{{ collateral.currency.symbol }}"
            height="32px"
            [ngStyle]="{ 'margin-left': '-10px' }"
            class="bg-white rounded-full border-2 border-white"
          ></sc-icon>
          <div
            *ngIf="i === maxCurrenciesToShow - 1"
            class="text-sm font-normal text-slate-500 ml-1"
          >
            +{{ remainingCollaterals }} more
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</section>

<section *ngIf="this.user && this.user.ethAddress && !isFetching" class="pb-10">
  <h2 class="mt-6">All Financing</h2>
  <hr class="mb-4" />
  <div class="table-wrapper">
    <table>
      <thead>
        <tr>
          <th scope="col">Amount</th>
          <th scope="col">Collateral</th>
          <th scope="col">Interest</th>
          <th scope="col">Status</th>
          <th scope="col">Created</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let loan of loans"
          [routerLink]="[
            '../credit-line/' + loan.contractAddress + '/loan/',
            loan.id
          ]"
          class="cursor-pointer"
        >
          <td class="items-center">
            <div class="flex">
              <sc-icon
                [src]="icons[loan.underlying.symbol]"
                width="32px"
                height="32px"
                class="self-center mb-2"
              >
              </sc-icon>
              <div class="pl-3">
                <div class="text-base font-medium">
                  {{
                    loan.loanAmount
                      | weiToCurrency : loan.underlying
                      | number : "1.2-6"
                  }}
                  {{ loan.underlying.symbol }}
                </div>
                <div class="font-normal text-sc-success">
                  {{
                    loan.loanAmount
                      | weiToCurrency : loan.underlying : "USD"
                      | currency : "USD" : "symbol-narrow" : "1.2-2"
                  }}
                </div>
              </div>
            </div>
          </td>
          <td class="items-center">
            <div class="flex">
              <sc-icon
                [src]="icons[loan.collateral.symbol]"
                width="32px"
                height="32px"
                class="self-center mb-2"
              >
              </sc-icon>
              <div class="pl-3">
                <div class="text-base font-medium">
                  {{
                    loan.currentCollateralAmount || loan.collateralInfo.amount
                      | weiToCurrency : loan.collateral
                      | number : "1.2-6"
                  }}
                  {{ loan.collateral.symbol }}
                </div>
                <div class="font-normal text-sc-success">
                  {{
                    loan.currentCollateralAmount || loan.collateralInfo.amount
                      | weiToCurrency : loan.collateral : "USD"
                      | currency : "USD" : "symbol-narrow" : "1.2-2"
                  }}
                </div>
              </div>
            </div>
          </td>
          <td class="font-medium">
            {{ loan.netLoanInterestRate | number : "1.2-2" }}%
          </td>
          <td>
            <div class="flex items-center">
              <div
                class="pl-1 text-sm font-medium sm pill"
                [ngClass]="{
                  default:
                    loan.loanStatus === 'TERMINATED' ||
                    loan.loanStatus === 'REPAID',
                  primary:
                    loan.loanStatus === 'MATCHED' ||
                    loan.loanStatus === 'NEW REQUEST',
                  success: loan.loanStatus === 'ACTIVE',
                  danger:
                    loan.loanStatus === 'BAD LOAN' ||
                    loan.loanStatus === 'BADLOAN REPAID'
                }"
              >
                {{ loan.loanStatus | titlecase }}
              </div>
            </div>
          </td>
          <td>
            <div class="flex items-center">
              <div class="pl-1 text-sm font-normal text-slate-500">
                {{ loan.createdAt | dateAgo }}
              </div>
            </div>
          </td>
          <td>
            <button
              class="primary-inverse sm"
              [routerLink]="[
                '../credit-line/' + loan.contractAddress + '/loan/',
                loan.id
              ]"
            >
              Details
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</section>
