import { AccountCenterOptions } from "@web3-onboard/core/dist/types"
import { LOGO } from 'src/assets/images/svg-icons'
import { environment } from 'src/environments/environment';
import injectedModule, { ProviderLabel } from '@web3-onboard/injected-wallets';
import walletConnectModule from '@web3-onboard/walletconnect'
import type { AppMetadata, WalletInit, Chain } from '@web3-onboard/common';
import uauthModule from '@web3-onboard/uauth';

const injected = injectedModule({
  // show following wallets even when extension not installed
  // displayUnavailable: [ProviderLabel.MetaMask, ProviderLabel.Bitget, ProviderLabel.Coinbase, ProviderLabel.BlockWallet]
});

const uauth = uauthModule({
  clientID: environment.web3.unstoppableClientId,
  redirectUri: environment.baseUrl,
  scope: "openid wallet email:optional profile:optional social:optional",
  walletConnectProjectId: environment.web3.walletConnectClientId
});

const walletConnect = walletConnectModule({
  version: 2,
  projectId: environment.web3.walletConnectClientId,
  requiredChains: [environment.web3.ethereumNetworkId],
  dappUrl: environment.baseUrl,
} as any);

export const CHAINS: Chain[] = [
  {
    id: '0x2be3', // in hexadecimel
    token: 'ISLM',
    label: 'Haqq Mainnet',
    rpcUrl: `https://haqq-evm.publicnode.com`
  }, {
    id: '0xd3c3',
    token: 'ISLMT',
    label: 'Haqq Testnet',
    rpcUrl: `https://rpc.eth.testedge2.haqq.network`
  }
];

export const APP_METADATA: AppMetadata = {
  name: 'BarakaFi',
  icon: LOGO.smallLogo,
  logo: LOGO.walletSelect,
  description: 'AI-driven DeFi Lending Marketplace',
  explore: environment.baseUrl,
  recommendedInjectedWallets: [
    { name: 'MetaMask', url: 'https://metamask.io', },
    { name: 'Coinbase', url: 'https://www.coinbase.com/wallet' },
    { name: 'Bitget', url: 'https://web3.bitget.com/en/wallet-download' },
    { name: 'BlockWallet', url: 'https://blockwallet.io/' }
  ],
};

export const ACCOUNT_CENTER: AccountCenterOptions = {
  desktop: { enabled: false },
  mobile: { enabled: false }
};

export const WALLETS: WalletInit[] = [
  injected,
  walletConnect,
  uauth
];

export const SIGN_LOAN_TYPES = {
  EIP712Domain: [
    { name: 'name', type: 'string' },
    { name: 'version', type: 'string' },
    { name: 'chainId', type: 'uint256' },
    { name: 'verifyingContract', type: 'address' }
  ],
  LoanRequest: [
    { name: 'loanId', type: 'bytes32' },
    { name: 'loanInterestRate', type: 'uint64' },
    { name: 'loanTerm', type: 'uint64' },
    { name: 'loanAmount', type: 'uint256' },
    { name: 'fee', type: 'uint256' },
    { name: 'initialCollateralAmount', type: 'uint256' },
    { name: 'collateralAddress', type: 'address' },
    { name: 'underlyingAddress', type: 'address' }
  ]
};

export const cTokens = {
  cETH: "0x4Ddc2D193948926D02f9B1fE9e1daa0718270ED5",
  cDAI: "0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643",
  cUSDC: "0x39AA39c021dfbaE8faC545936693aC917d5E7563",
  cUSDT: "0xf650C3d88D12dB855b8bf7D11Be6C55A4e07dCC9"
};
