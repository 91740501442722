export const environment = {
  production: true,
  name: 'prod',
  baseUrl: 'https://p2pfinex.com',
  apiUrl: 'https://d8vthxr1qz4ne.cloudfront.net/graphql',
  sentryUrl: 'https://88527e7926ae18536e29e710090047a0@o512640.ingest.us.sentry.io/4507083009294336',
  gtagId: '',
  pixelId:  'pI70geqgLFSzUEoDBeoq',
  fingerprintKey: '5qUbs6lak2D3RaXxryhm',
  banners: {
    homepage: 'SMARTCREDITBETA2Udbj-efab18e7-2afc-40d2-9c42-56aa7d2c4fdc',
    borrow: 'SMARTCREDITBETA2Udbj-d6e84693-678e-4f86-add8-e30e156641b4',
    lend: 'SMARTCREDITBETA2Udbj-70c3005a-3e69-4a19-b647-2936196ff825',
    stake: 'SMARTCREDITBETA2Udbj-0c39ec28-42a7-4557-a9ed-785093929662',
    leverage: 'SMARTCREDITBETA2Udbj-a66ba590-ec67-4a8f-a017-247149b17281',
    earn: 'SMARTCREDITBETA2Udbj-a42b24f1-139e-435d-a4da-9f3602284621'
  },
  web3: {
    ethereumNetworkId: 11235,
    chainId: '0x2be3',
    walletConnectBridge: 'https://bridge.walletconnect.org/hello/',
    infuraKey: '0bfdfae0ab0a44d8bfecbb05ba45463a',
    blocknativeId: '65c7be5d-2200-49b1-aa0c-9af43ba3c86c',
    walletConnectClientId: 'b92e67edc8152afb9bf80b8beaf899c2',
    walletAuthMessage: "Sign this message to authenticate with https://barakafi.com. This wont cost you any ISLM.",
    walletVerifyMessage: "Sign this message to prove you have access to this wallet on https://barakafi.com. This wont cost you any ISLM.",
    smartCreditContract: '0x2c948240c0F383510E1B20a4B9064309fEc7196B',
    insurerAddress: '0xD7F55002EAEAE9136Ad8bcf67D744FfC221d7d33',
    rewardsContract: '0xD88080814403597a601720B66E5AA12d4A42e675',
    stakingWithdrawMs: 3600000,
    unstoppableClientId: '3e993a97-f5fc-4072-bebb-9b6dab4677bb',
    lidoWithdrawAddress:  '0x1583C7b3f4C3B008720E6BcE5726336b0aB25fdd'
  },
  config: {
    leverageMinAmountUsd: 10
  }
};
