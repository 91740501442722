import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/pages/dashboard/dashboard.component';
import { PrivacyComponent } from './legal/pages/privacy/privacy.component';
import { SupportComponent } from './legal/pages/support/support.component';
import { TermsComponent } from './legal/pages/terms/terms.component';
import { ErrorPageComponent } from './shared/components/error-page.component';
import { VerifyEmailComponent } from './shared/pages/verify-email/verify-email.component';
import { ProfileGuard } from './core/guards/profile.guard';

const routes: Routes = [{
  path: '',
  component: DashboardComponent,
  title: 'BarakaFi - Fixed Term & Fixed Repurchase Rate for Buyers, Fixed Income Funds for Sellers'
}, {
  path: 'purchase',
  loadChildren: () => import('./borrowing/borrowing.module')
    .then(m => m.BorrowingModule),
  title: 'Purchase with Fixed Term & Fixed Rate - BarakaFi'
}, {
  path: 'sell',
  loadChildren: () => import('./lending/lending.module')
    .then(m => m.LendingModule),
  title: 'Sell with Fixed Income Funds - BarakaFi'
},
//  {
//   path: 'stake',
//   loadChildren: () => import('./staking/staking.module')
//     .then(m => m.StakingModule),
//   title: 'Stake SMARTCREDIT Tokens - SmartCredit.io'
// },
{
  path: 'rewards',
  loadChildren: () => import('./rewards/rewards.module')
    .then(m => m.RewardsModule),
  title: 'Rewards - BarakaFi'
},
{
  path: 'profile',
  loadChildren: () => import('./profile/profile.module')
    .then(m => m.ProfileModule),
  title: 'Profile - BarakaFi',
  canActivate: [ProfileGuard]
}, {
  path: 'earn',
  loadChildren: () => import('./earn/earn.module')
    .then(m => m.EarnModule),
  title: 'Earn Crypto - BarakaFi'
},
// {
//   path: 'leveraged-staking',
//   loadChildren: () => import('./leveraging/leveraging.module')
//     .then(m => m.LeveragingModule),
//   title: 'Leveraged Staking - SmartCredit.io'
// },
{
  path: 'terms-of-service',
  component: TermsComponent,
  title: 'Terms of Service - BarakaFi'
}, {
  path: 'privacy-policy',
  component: PrivacyComponent,
  title: 'Privacy Policy - BarakaFi'
}, {
  path: 'support',
  component: SupportComponent,
  title: 'Support - BarakaFi'
}, {
  path: 'error',
  component: ErrorPageComponent,
  title: 'Error - BarakaFi'
}, {
  path: 'auth/verify/:token',
  component: VerifyEmailComponent,
  title: 'Verify Email - BarakaFi'
}, {
  path: "**",
  redirectTo: ''
}];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
