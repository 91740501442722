<div
  *ngIf="user && user.ethAddress"
  (click)="outClick ? (dropdownOpen = false) : (dropdownOpen = true)"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  class="wallet-btn flex items-center shadow-sm border text-center w-[152px] border-slate-200 rounded-full px-4 py-3 mx-auto text-sm font-semibold cursor-pointer bg-slate-50 hover:bg-white hover:text-sc-primary hover:border-sc-primary/50 hover:shadow transition duration-125 select-none"
>
  <sc-icon
    [src]="icons.wallet"
    size="0.4"
    width="0rem"
    height="0rem"
    classes="mb-8"
    class="h-[1.2rem] mr-6"
  ></sc-icon>
  <div *ngIf="!user.domain && user.ethAddress" class="mr-1 text-sm">
    {{ user.ethAddress | shortAddress : 5 : 4 }}
  </div>
  <div *ngIf="user.domain" class="text-sm truncate mr-1">
    {{ user.domain }}
  </div>
  <sc-icon
    [src]="icons.arrowRight"
    size="0.75"
    width="1rem"
    height="1rem"
    class="transition-all"
    [ngClass]="dropdownOpen ? 'rotate-180 mr-2' : 'mr-3'"
  ></sc-icon>
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="dropdownOpen"
    (overlayOutsideClick)="dropdownOpen = false; onOutClick()"
  >
    <ul class="menu-overlay mt-1 overflow-hidden">
      <li [routerLink]="['/profile']" (click)="dropdownOpen = false">
        <sc-icon
          [src]="icons.profile"
          size="0.4"
          width="1rem"
          height="0rem"
          classes="mb-8"
          class="h-[1.2rem] mr-4"
        ></sc-icon>
        Profile
      </li>
      <!-- <li [routerLink]="['/stake']" (click)="dropdownOpen = false">
        <sc-icon [src]="icons.stake" width="0.6rem" height="0rem" classes="ml-[0.4rem] mb-8"
          class="h-[1.2rem] mr-4"></sc-icon>
        Stake SMARTCREDIT
      </li>-->
      <!-- <li [routerLink]="['/rewards']" (click)="dropdownOpen = false">
        <sc-icon
          [src]="icons.myrewards"
          width="0.6rem"
          height="0rem"
          classes="ml-[0.4rem] mb-8"
          class="h-[1.2rem] mr-4"
        ></sc-icon>
        Rewards
      </li> -->
      <li (click)="dropdownOpen = false; onLogOut()">
        <sc-icon
          [src]="icons.logOut"
          size="0.4"
          width="1rem"
          height="0rem"
          classes="mb-8"
          class="h-[1.2rem] mr-4"
        ></sc-icon>
        Log Out
      </li>
    </ul>
  </ng-template>
</div>
<button
  *ngIf="!user || !user.ethAddress"
  class="nostyle"
  (click)="onLogIn()"
  [disabled]="connecting"
  class="nostyle w-[152px] shadow border border-slate-200 rounded-full py-3 mx-auto font-semibold cursor-pointer bg-slate-50 hover:bg-white hover:text-sc-primary hover:border-sc-primary/50 hover:shadow transition duration-125"
  [ngClass]="{ 'bg-slate-50': connecting }"
>
  <div *ngIf="!connecting" class="text-sm whitespace-nowrap text-center">
    Connect Wallet
  </div>
  <div
    *ngIf="connecting"
    class="text-sm whitespace-nowrap text-center text-slate-500"
  >
    Connecting...
  </div>
</button>
