import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, debounceTime, filter, map, Subject, take, takeUntil, timer } from 'rxjs';
import { BorrowingService } from 'src/app/core/services/borrowing.service';
import { CurrenciesService } from 'src/app/core/services/currencies.service';
import { CollateralStats, CreditLine } from 'src/app/shared/interfaces/borrowing/credit-line';
import { Loan } from 'src/app/shared/interfaces/borrowing/loan';
import { Currency } from 'src/app/shared/interfaces/currencies/currency';
import { User } from 'src/app/shared/interfaces/user/user';
import { AppState } from 'src/app/store/app.reducer';
import { CRYPTO_ICONS, GENERAL_ICONS } from 'src/assets/images/svg-icons';
import { CollateralActionsDialog } from '../../components/collateral-actions/collateral-actions.dialog';

@Component({
  selector: 'app-credit-line',
  templateUrl: './credit-line.component.html',
  styleUrls: ['./credit-line.component.scss']
})
export class CreditLineComponent implements OnInit, OnDestroy {
  public icons = { ...GENERAL_ICONS, ...CRYPTO_ICONS };
  private creditLineId: string;
  public destroy$ = new Subject<void>();
  public creditLine: CreditLine;
  public loans: Loan[];
  public user: User;
  public initialVisibleCurrencies: number = 4;
  public showAllCollaterals: boolean;
  public showAllAssets: boolean;
  private collateralActionsDialogRef: DialogRef<CollateralActionsDialog>;
  public isFetching: boolean = false;

  constructor(
    private borrowingService: BorrowingService,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private currenciesService: CurrenciesService,
    public dialog: Dialog
  ) {
    this.creditLineId = this.route.snapshot.paramMap.get("creditLineAddress");
    this.currenciesService.fetchCurrencies ? this.currenciesService.fetchCurrencies() : null;
  }

  ngOnInit(): void {
    this.isFetching = true;
    timer(1000).pipe(takeUntil(this.destroy$))
      .subscribe(async () => {
        this.store.select(state => state.core.user)
          .pipe(filter(user => !!user), takeUntil(this.destroy$))
          .subscribe((user: User) => {
            this.user = user;
            // this.borrowingService.getCreditLines({ ownerAddress: user.ethAddress })
          })

        await this.borrowingService.getCreditLine(this.creditLineId).then(() => {

          this.store.select(state => state.borrowing.creditLine)
            .pipe(
              filter(cLs => cLs !== undefined && cLs !== null),
              takeUntil(this.destroy$)).subscribe((creditLine) => {
                this.creditLine = creditLine;
              });
        })

        await this.borrowingService.getLoans(this.creditLine.ownerAddress, this.user.creditData.riskRating).then(async () => {

          this.store.select(state => state.borrowing.loans)
            .pipe(
              filter(loans => !!loans && loans.length > 0),
              map(loans => loans.filter(loan => loan.contractAddress === this.creditLine.contractAddress)),
              take(10),
              takeUntil(this.destroy$)
            )
            .subscribe((loans: Loan[]) => {
              this.loans = loans;
              // this.borrowingService.updateCreditLineAsset(this.creditLine, this.loans);
            })
        })
        this.isFetching = false;

        // this.store.select(state => state.borrowing.creditLines)
        //   .pipe(
        //     filter(creditLines => !!creditLines && creditLines.length > 0),
        //     map((cLs: CreditLine[]): CreditLine => cLs.find(cL => cL.contractAddress === this.creditLineId)),
        //     take(1),
        //     debounceTime(300),
        //     takeUntil(this.destroy$)
        //   )
        //   .subscribe((creditLine: CreditLine) => {
        //     this.creditLine = creditLine;
        //     this.loans = [];
        //     this.store.select(state => state.borrowing.loans)
        //       .pipe(
        //         filter(loans => !!loans && loans.length > 0),
        //         map(loans => loans.filter(loan => loan.contractAddress === this.creditLine.contractAddress)),
        //         take(10),
        //         takeUntil(this.destroy$)
        //       )
        //       .subscribe((loans: Loan[]) => {
        //         this.loans = loans;
        //         this.borrowingService.updateCreditLineAsset(this.creditLine, this.loans);
        //       })
        //   })

        //   combineLatest({
        //     currencies: this.store.select(state => state.dashboard.currencies)
        //       .pipe(filter(currencies => !!currencies), take(1)),
        //     creditLine: this.store.select(state => state.borrowing.creditLine)
        //       .pipe(filter(cLs => cLs !== undefined && cLs !== null),
        //         take(1),
        //       )
        //   }).pipe(takeUntil(this.destroy$))
        //     .subscribe(async ({ currencies, creditLine }) => {
        //       this.creditLine = creditLine;
        //       if (!creditLine.collaterals || creditLine.collaterals.length === 0) {
        //         this.borrowingService.getCreditLineCurrenciesAndBalances(creditLine, currencies.underlyingCurrencies, currencies.collateralCurrencies).subscribe(() => {
        //           this.store.select(state => state.borrowing.creditLines)
        //             .pipe(
        //               filter(creditLines => !!creditLines && creditLines.length > 0),
        //               map((cLs: CreditLine[]): CreditLine => cLs.find(cL => cL.contractAddress === this.creditLineId)),
        //               debounceTime(50),
        //               takeUntil(this.destroy$)
        //             )
        //             .subscribe((creditLine: CreditLine) => {
        //               this.creditLine = creditLine;
        //             })
        //         })
        //       }
        //     })
      })
  }

  showMoreCollaterals() {
    this.showAllCollaterals = true;
  }

  showLessCollaterals() {
    this.showAllCollaterals = false;
  }

  showMoreAssets() {
    this.showAllAssets = true;
  }

  showLessAssets() {
    this.showAllAssets = false;
  }

  openCollateralActionsDialog(collateral: CollateralStats) {
    this.collateralActionsDialogRef = this.dialog.open(CollateralActionsDialog, {
      id: 'collateral-actions',
      minWidth: '300px',
      maxWidth: '300px',
      autoFocus: false,
      data: {
        ethAddress: this.user.ethAddress,
        collateral: collateral,
        requiredCollateral: 0,
      }
    });

    this.collateralActionsDialogRef.componentInstance['withdraw']
      .pipe(takeUntil(this.destroy$))
      .subscribe((amount) => {
        this.borrowingService.withdrawCollateral(this.creditLine, collateral, amount);
        this.collateralActionsDialogRef.close();
      })

    this.collateralActionsDialogRef.componentInstance['deposit']
      .pipe(takeUntil(this.destroy$))
      .subscribe((amount) => {
        this.borrowingService.depositToCreditLine(this.creditLine, collateral, amount);
        this.collateralActionsDialogRef.close();
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
