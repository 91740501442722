import { Pipe, PipeTransform } from '@angular/core';
import BigNumber from 'bignumber.js';
import { Currency } from '../interfaces/currencies/currency';

@Pipe({
  name: 'weiToCurrency'
})
export class WeiToCurrencyPipe implements PipeTransform {

  transform(value: string | number | bigint, currency: Currency, targetCurrency?: 'USD'): number {
    if (typeof value === 'bigint' || typeof value === 'number') {
      value = value.toString();
    }

    const decimalPlaces = new BigNumber(10).pow(currency?.decimalPlaces || 0);
    const exchangeRate = currency?.exchangeRate || 1;

    if (targetCurrency === 'USD') {
      return new BigNumber(value).div(decimalPlaces).times(exchangeRate).toNumber();
    } else {
      return new BigNumber(value).div(decimalPlaces).toNumber();
    }
  }
}
