import { Component, DestroyRef, OnDestroy, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import * as BorrowingActions from '../../store/borrowing.actions';
import { AppState } from '../../../store/app.reducer';
import { BorrowingService } from 'src/app/core/services/borrowing.service';
import { CreditLine } from 'src/app/shared/interfaces/borrowing/credit-line';
import { User } from 'src/app/shared/interfaces/user/user';
import { CurrenciesService } from 'src/app/core/services/currencies.service';
import { LoanData } from 'src/app/shared/interfaces/borrowing/loan-data';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Currency } from 'src/app/shared/interfaces/currencies/currency';
import { catchError, debounceTime, filter, forkJoin, interval, map, merge, Subject, take, takeUntil, tap, timer } from 'rxjs';
import { RewardsService } from 'src/app/core/services/rewards.service';
import { Web3Service } from 'src/app/core/web3/web3.service';
import { CRYPTO_ICONS, GENERAL_ICONS, MENU_ICONS } from 'src/assets/images/svg-icons';
import { Dialog } from '@angular/cdk/dialog';
import { CreateLoanDialog } from '../../components/create-loan/create-loan.dialog';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ParamsInformation } from 'src/app/shared/interfaces/borrowing/params-information';
import { RiskRateFee } from 'src/app/shared/interfaces/borrowing/risk-rate-fee';
import { regEx } from 'src/config/app.config';
import { currencies } from 'src/app/shared/constants';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import BigNumber from 'bignumber.js';
import seoConfig from '../../../../assets/static/seo.config.json';
import borrowConfig from '../../../../assets/static/borrow.config.json';
import { DEFAULT_REWARDS, PRESET_VALUES } from '../../borrow.config';
import { BorrowingRewards } from 'src/app/shared/interfaces/borrowing/borrowing-rewards';
import { SimpleAdvancedInfoComponent } from 'src/app/shared/components/simple-advanced-info.component';
import { environment } from 'src/environments/environment';
import { PixelService } from 'src/app/core/services/pixel.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StatsService } from 'src/app/core/services/stats.service';
import { DashboardStats } from 'src/app/shared/interfaces/statistics/dashboard-stats';

@Component({
  selector: 'app-borrow',
  templateUrl: './borrow.component.html',
  styleUrls: ['./borrow.component.scss']
})

export class BorrowComponent implements OnInit, OnDestroy {
  currenciesService = inject(CurrenciesService);
  borrowingService = inject(BorrowingService);
  rewardsService = inject(RewardsService);
  web3Service = inject(Web3Service);
  statsService = inject(StatsService);
  store = inject(Store<AppState>);
  toastr = inject(ToastrService);
  route = inject(ActivatedRoute);
  fb = inject(FormBuilder);
  dialog = inject(Dialog);
  title = inject(Title);
  meta = inject(Meta);
  user: User;
  assets: Currency[];
  collaterals: Currency[];
  userCreditLines: CreditLine[];
  creditLines: CreditLine[];
  creditLine: CreditLine | undefined;
  loanData: LoanData;
  loanCollateral: Currency;
  loanAsset: Currency;
  paramsInformation: ParamsInformation;
  creatingLoanRequest: boolean;
  icons = { ...GENERAL_ICONS, ...CRYPTO_ICONS, ...MENU_ICONS };
  presetValues = PRESET_VALUES;
  borrowForm: FormGroup;
  overlayOutsideClicked: boolean;
  assetOpen: boolean;
  collateralOpen: boolean;
  minLoanAmountInTokens: number;
  maxLoanAmountInTokens: number;
  rewardToken: Currency;
  noLiquidity: boolean;
  pageDetails: any;
  tokenRewards: BorrowingRewards = DEFAULT_REWARDS
  advancedMode: boolean;
  editMode = {
    collateralRatio: false,
    duration: false
  }
  collateralRatioLimits = {
    min: 100,
    max: 300
  }
  loanOver1kAwarded: boolean;
  lastWeekRewards: any;
  stats: DashboardStats;
  defaultRiskRateFee: RiskRateFee;
  environment = environment;
  pixelService = inject(PixelService);
  destroy$ = new Subject<void>();
  destroyRef = inject(DestroyRef);
  isValidating: boolean = false;
  isUpdating: boolean = false;
  public isCalculating: boolean = false;
  private isDialogOpen: boolean = false;
  public isDashboardBtnEnable: boolean = false;
  constructor() {
    this.borrowingService.getBorrowingFormInitData ? this.borrowingService.getBorrowingFormInitData() : false;

    if (!this.loanAsset) {
      this.currenciesService.fetchCurrencies();
    }
    this.rewardsService.getLastWeekRewards ? this.rewardsService.getLastWeekRewards() : false;
    this.statsService.getDashboardStats ? this.statsService.getDashboardStats() : false;

    this.store.select("dashboard")
      .pipe(
        filter(dashboardStore => !!dashboardStore),
        takeUntil(this.destroy$)
      )
      .subscribe((dashboardStore => {
        this.stats = dashboardStore.stats;
        this.rewardToken = dashboardStore?.currencies?.underlyingCurrencies?.find((a: Currency) => a.symbol === dashboardStore?.stats?.rewardConfigData?.rewardToken);

      }))

    this.borrowForm = this.fb.group({
      asset: ['', Validators.required],
      collateral: ['', Validators.required],
      amount: [, [Validators.required, Validators.pattern(regEx.numbers), Validators.min(0.000001), Validators.max(100000000)]],
      duration: [, [Validators.required, Validators.pattern(regEx.numbers), Validators.min(0), Validators.max(180)]],
    })

    this.borrowForm.controls['asset'].setValidators([
      Validators.required,
      this.valuesCannotBeSame('asset', 'collateral'),
    ]);

    this.borrowForm.controls['collateral'].setValidators([
      Validators.required,
      this.valuesCannotBeSame('collateral', 'asset'),
    ]);

    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        this.presetValues.asset = params['a'];
        this.presetValues.collateral = params['c'];
        this.presetValues.amount = params['m'];
        this.presetValues.duration = params['d'];
      });
    if (this.presetValues.asset) {
      this.pageDetails = borrowConfig.tokens.find(r => r.token === this.presetValues.asset)
    } else {
      this.pageDetails = seoConfig.routes.find(r => r.path === '/purchase');
    }
    this.addSeoConfig(this.pageDetails);
  }

  ngOnInit(): void {


    this.store.select(state => state.core.user)
      .pipe(filter(user => !!user), takeUntil(this.destroy$))
      .subscribe((user: User) => {
        this.user = user;
        this.borrowingService.getCreditLines({ ownerAddress: user.ethAddress })
        // this.borrowingService.getLoans(user.ethAddress, user.creditData.riskRating)
      })

    this.store.select(state => state.borrowing.creditLines)
      .pipe(take(5), takeUntil(this.destroy$))
      .subscribe((creditLines: CreditLine[]) => {
        if (creditLines.length > 0) {
          this.isDashboardBtnEnable = true;
        }
      })

    this.pixelService.getPixelData().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
    this.onFormValueChange();
    forkJoin({
      currencies: this.store.select(state => state.dashboard.currencies)
        .pipe(filter(currencies => !!currencies), take(1)),
      riskRateFees: this.store.select(state => state.borrowing.riskRateFees)
        .pipe(filter(riskRateFees => !!riskRateFees && riskRateFees.length !== 0), take(1))
    })
      .pipe(
        takeUntil(this.destroy$))
      .subscribe(({ currencies, riskRateFees }) => {
        if (currencies && currencies.underlyingCurrencies && currencies.collateralCurrencies) {
          this.defaultRiskRateFee = riskRateFees[0];
          this.assets = currencies.underlyingCurrencies;
          this.collaterals = currencies.collateralCurrencies;
          this.loanAsset = this.assets?.find((a: Currency) => a.symbol === (this.presetValues.asset ? this.presetValues.asset : 'ISLM'))
          this.loanCollateral = this.collaterals?.find((c: Currency) => c.symbol === (this.presetValues.collateral ? this.presetValues.collateral : 'USDC'))
          this.borrowingService.updateLoanAsset(this.loanAsset);
          this.borrowingService.updateLoanCollateral(this.loanCollateral);
          this.store.select(state => state.borrowing.paramsInformation)
            .pipe(filter(paramsInformation => !!paramsInformation && paramsInformation.minLoanAmount !== null), takeUntil(this.destroy$))
            .subscribe((paramsInformation: ParamsInformation) => {
              this.paramsInformation = paramsInformation;
              this.minLoanAmountInTokens = +(paramsInformation.minLoanAmount / this.loanAsset.exchangeRate * 1000000).toFixed(0) / 1000000;
              this.maxLoanAmountInTokens = +(paramsInformation.maxLoanAmount / this.loanAsset.exchangeRate * 1000000).toFixed(0) / 1000000;
              this.borrowForm.controls['amount'].setValue(this.presetValues.amount ? this.presetValues.amount : this.minLoanAmountInTokens, { emitEvent: true });
              this.borrowForm.controls['amount'].addValidators([
                Validators.min(this.minLoanAmountInTokens),
                Validators.max(this.maxLoanAmountInTokens),
                Validators.pattern(regEx.numbers),
                Validators.required
              ]);
              this.updateDuration(this.presetValues.duration ? this.presetValues.duration : this.paramsInformation.minLoanTerm);
            })

        }
      })

    this.store.select(state => state.borrowing.loanAsset)
      .pipe(filter(loanAsset => !!loanAsset), takeUntil(this.destroy$))
      .subscribe((loanAsset: Currency) => {
        this.loanAsset = loanAsset;
        this.borrowForm.controls['asset'].setValue(loanAsset.symbol, { emitEvent: true });
      })

    this.store.select(state => state.borrowing.loanCollateral)
      .pipe(filter(loanCollateral => !!loanCollateral), takeUntil(this.destroy$))
      .subscribe((loanCollateral: Currency) => {
        this.loanCollateral = loanCollateral;
        this.borrowForm.controls['collateral'].setValue(loanCollateral.symbol, { emitEvent: true });
      })

    this.store.select(state => state.core.user)
      .pipe(filter(user => !!user), takeUntil(this.destroy$))
      .subscribe((user: User) => {
        this.user = user;
        this.borrowingService.getCreditLines({ ownerAddress: this.user.ethAddress });
      })

    this.store.select(state => state.core.user)
      .pipe(filter(user => !!user), take(1), takeUntil(this.destroy$))
      .subscribe((user: User) => {
        this.borrowingService.updateLoanData({
          borrowerAddress: user.ethAddress,
          userRating: user.creditData.riskRating,
        })
      })

    this.store.select(state => state.borrowing.creditLines)
      .pipe(filter(creditLines => !!creditLines && creditLines.length > 0), takeUntil(this.destroy$))
      .subscribe((creditLines: CreditLine[]) => {
        this.userCreditLines = creditLines;
      })

    this.store.select(state => state.borrowing.lastWeekRewards)
      .pipe(filter(lastWeekRewards => !!lastWeekRewards), take(1), takeUntil(this.destroy$))
      .subscribe((lastWeekRewards: any) => {
        this.lastWeekRewards = lastWeekRewards;
      })

    this.store.select(state => state.borrowing.loanData)
      .pipe(filter(loanData => !!loanData), takeUntil(this.destroy$))
      .subscribe((loanData: LoanData) => {
        this.loanData = loanData;
        if (!this.advancedMode && this.loanData.liquidationLimit) {
          this.collateralRatioLimits = {
            min: +(this.loanData.liquidationLimit * 1.05).toFixed(2),
            max: +(this.loanData.liquidationLimit * 1.05 + 150).toFixed(2),
          }
        }
      })

    this.store.select(state => state.borrowing.creatingLoanRequest)
      .pipe(takeUntil(this.destroy$))
      .subscribe((creatingLoanRequest: boolean) => {
        this.creatingLoanRequest = creatingLoanRequest;
      })

    this.store.select(state => state.dashboard.currencies)
      .pipe(
        filter(allCurrencies => !!allCurrencies),
        map(currencies => {
          let collateral: Currency;
          if (currencies && currencies.collateralCurrencies) {
            if (this.loanCollateral) {
              collateral = currencies.collateralCurrencies.find(collateral => collateral.symbol === this.loanCollateral.symbol)
            } else {
              collateral = currencies.collateralCurrencies[0];
            }
            return collateral;
          }
          return undefined;
        }),
        takeUntil(this.destroy$))
      .subscribe((collateral: Currency | undefined) => {
        if (collateral) {
          if (collateral.liquidityPool) {
            this.noLiquidity = new BigNumber(collateral.liquidityPool.maxLoanCollateral)
              .isLessThan(new BigNumber(collateral.liquidityPool.totalLockedCollateralVolumeInUSD)
                .plus(this.loanData.collateralAmount * this.loanCollateral.exchangeRate));
          } else {
            this.noLiquidity = false;
          }
          if (this.noLiquidity) {
            this.borrowForm.controls['collateral'].setErrors({ noLiquidity: true });
          } else {
            this.borrowForm.controls['collateral'].setErrors(null);
          }
        }
      })

    this.store.select(state => state.borrowing.loanData)
      .pipe(
        filter(loanData => !!loanData),
        debounceTime(500),
        takeUntil(this.destroy$)
      )
      .subscribe((loanData: LoanData) => {
        this.store.select(state => state.borrowing.loanOver1kAwarded)
          .pipe(
            debounceTime(100),
            takeUntil(this.destroy$)
          )
          .subscribe((loanOver1kAwarded: boolean) => {
            this.loanOver1kAwarded = loanOver1kAwarded;
            this.calculateTokenRewards(this.lastWeekRewards, loanData, this.loanAsset, this.rewardToken, this.loanOver1kAwarded);
          })
        this.store.select(state => state.borrowing.loanAsset)
          .pipe(
            filter(loanAsset => !!loanAsset),
            debounceTime(50),
            takeUntil(this.destroy$)
          )
          .subscribe((loanAsset: Currency) => {
            if (loanData.loanAmount) {
              this.rewardsService.getUserRewardTypes(this.user ? this.user.ethAddress : undefined, loanAsset, this.loanData)
            }
          })

        if (this.borrowForm.valid && loanData.loanAmountUsd && !loanData.liquidationProbability && loanData.collateralAmount) {

          this.borrowingService.getLiquidationProbability(this.loanData)
        }
      })
  }

  calculateTokenRewards(rewardData: any, loanData: LoanData, underlying: Currency, rewardToken: Currency, firstLoan: boolean = true) {
    if (this.borrowForm.valid && this.loanData.loanTerm <= 180) {
      const loanValueUsd = loanData.loanAmount * underlying.exchangeRate;
      const weeks = Math.floor(loanData.loanTerm / 7);
      const baseReward = +rewardData?.borrowerRewards * loanValueUsd / +rewardData?.totalBorrowedVolumeInUSD * weeks;
      const firstLoanReward = firstLoan ? 0 : 50;
      const campaignReward = loanValueUsd * (loanData.loanRate / 100) * (this.paramsInformation.campaignReward / 100) / rewardToken?.exchangeRate;
      const totalRewards = baseReward + firstLoanReward + campaignReward;
      this.tokenRewards = { baseReward, firstLoanReward, campaignReward, totalRewards }
    }
  }

  onFormValueChange() {

    this.borrowForm.valueChanges
      .pipe(debounceTime(200), takeUntil(this.destroy$))
      .subscribe(val => {
        if (this.borrowForm.valid && !this.advancedMode) {
          this.borrowingService.getCollateralToLoan(
            this.borrowForm.value,
            this.loanCollateral,
            this.loanAsset,
            this.user ? this.user.creditData.riskRating : 1
          )
        }
        if (this.loanData.loanAmountUsd && this.loanData.liquidationProbability && this.borrowForm.valid) {
          this.isCalculating = true;
          timer(1000).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
              this.isCalculating = false;

              this.borrowingService.updateLoanData({
                collateralAmountUsd: new BigNumber(this.loanData.collateralAmount).times(this.loanCollateral.exchangeRate).toNumber(),
              })
              this.borrowingService.getLiquidationProbability(this.loanData);
            })
        }
      })

    this.borrowForm.controls['asset'].valueChanges
      .subscribe((val) => {
        if (!this.isValidating) {
          this.isValidating = true;
          this.borrowForm.controls['collateral'].updateValueAndValidity();
          this.loanAsset = this.assets.find((c: Currency) => c.symbol === val);
          this.isValidating = false
        }
      });

    this.borrowForm.controls['collateral'].valueChanges
      .subscribe((val) => {
        if (!this.isValidating) {
          this.isValidating = true;
          this.borrowForm.controls['asset'].updateValueAndValidity();
          this.loanCollateral = this.collaterals.find(c => c.symbol === val);
          this.isValidating = false
        }
      });

    this.borrowForm.controls['asset'].valueChanges
      .pipe(debounceTime(50), takeUntil(this.destroy$))
      .subscribe((val) => {
        if (!this.borrowForm.controls['asset'].hasError('valuesCannotBeSame')) {
          this.updateAmount();
          this.borrowingService.updateLoanAsset(this.loanAsset);
          this.borrowingService.updateLoanData({
            underlyingSymbol: this.loanAsset.symbol,
            underlyingCurrency: this.loanAsset.name,
            underlyingDecimalPlaces: this.loanAsset.decimalPlaces,
            underlyingAddress: this.loanAsset.ethAddress
          });
          if (val === 'SMARTCREDIT') {
            this.borrowForm.controls['duration'].value < 90 ? this.updateDuration(90) : null;
          } else {
            this.updateDuration(this.presetValues.duration ? this.presetValues.duration : this.paramsInformation.minLoanTerm);
          }
          this.borrowForm.controls['collateral'].value ? this.updateCreditLine() : null;
          this.borrowingService.getCollateralToLoan(
            this.borrowForm.value,
            this.loanCollateral,
            this.loanAsset,
            this.user ? this.user.creditData.riskRating : 1
          )
          timer(1000).pipe(takeUntil(this.destroy$)).subscribe(() => {
            if (this.advancedMode) {
              this.borrowForm.controls['collateralRatio'].setValue(this.loanData.collateralRatio, { emitEvent: false });
            }
            this.collateralRatioLimits = {
              min: +(this.loanData.liquidationLimit * 1.05).toFixed(2),
              max: +(this.loanData.liquidationLimit * 1.05 + 150).toFixed(2),
            }
            if (this.advancedMode) {
              if (this.borrowForm.controls['collateralRatio'].value < this.collateralRatioLimits.min) {
                timer(1200).pipe(takeUntil(this.destroy$)).subscribe(() => {
                  this.borrowForm.controls['collateralRatio'].setValue(this.collateralRatioLimits.min, { emitEvent: false });
                  this.borrowingService.updateLoanData({ collateralRatio: this.collateralRatioLimits.min });
                })
              } else if (this.borrowForm.controls['collateralRatio'].value > this.collateralRatioLimits.max) {
                timer(1200).pipe(takeUntil(this.destroy$)).subscribe(() => {
                  this.borrowForm.controls['collateralRatio'].setValue(this.collateralRatioLimits.max, { emitEvent: false });
                  this.borrowingService.updateLoanData({ collateralRatio: this.collateralRatioLimits.max });
                })
              }
            }
          })
        }
      })

    this.borrowForm.controls['collateral'].valueChanges
      .pipe(debounceTime(50), takeUntil(this.destroy$))
      .subscribe((val) => {
        if (!this.borrowForm.controls['collateral'].hasError('valuesCannotBeSame')) {
          this.borrowingService.updateLoanCollateral(this.loanCollateral);
          this.loanCollateral.depegged ? this.borrowForm.controls['collateral'].setErrors({ 'depegged': true }) : this.borrowForm.controls['collateral'].setErrors(null);
          this.borrowingService.updateLoanData({
            collateralSymbol: this.loanCollateral.symbol,
            collateralCurrency: this.loanCollateral.name,
            collateralDecimalPlaces: this.loanCollateral.decimalPlaces,
            collateralAddress: this.loanCollateral.ethAddress
          });
          if (currencies.UNISWAP_CURRENCIES.includes(this.loanCollateral.symbol)) {
            this.borrowingService.updateCollateralLiquidityPool(this.loanCollateral.symbol);
          }
          this.borrowForm.controls['asset'].value ? this.updateCreditLine() : null;
          this.borrowingService.getCollateralToLoan(
            this.borrowForm.value,
            this.loanCollateral,
            this.loanAsset,
            this.user ? this.user.creditData.riskRating : 1
          )
          timer(1000).pipe(takeUntil(this.destroy$)).subscribe(() => {
            if (this.advancedMode) {
              this.borrowForm.controls['collateralRatio'].setValue(this.loanData.collateralRatio, { emitEvent: false });
            }
            this.collateralRatioLimits = {
              min: +(this.loanData.liquidationLimit * 1.05).toFixed(2),
              max: +(this.loanData.liquidationLimit * 1.05 + 150).toFixed(2),
            }
            if (this.advancedMode) {
              if (this.borrowForm.controls['collateralRatio'].value < this.collateralRatioLimits.min) {
                timer(1200).pipe(takeUntil(this.destroy$)).subscribe(() => {
                  this.borrowForm.controls['collateralRatio'].setValue(this.collateralRatioLimits.min, { emitEvent: false });
                  this.borrowingService.updateLoanData({ collateralRatio: this.collateralRatioLimits.min });
                })
              } else if (this.borrowForm.controls['collateralRatio'].value > this.collateralRatioLimits.max) {
                timer(1200).pipe(takeUntil(this.destroy$)).subscribe(() => {
                  this.borrowForm.controls['collateralRatio'].setValue(this.collateralRatioLimits.max, { emitEvent: false });
                  this.borrowingService.updateLoanData({ collateralRatio: this.collateralRatioLimits.max });
                })
              }
            }
          })
        }
      })

    this.borrowForm.controls['amount'].valueChanges
      .pipe(debounceTime(50), takeUntil(this.destroy$))
      .subscribe((val) => {
        if (this.borrowForm.controls['amount'].valid) {
          let formattedVal = val.toString();
          formattedVal.replace(/\D/g, '');
          if (this.loanAsset) {
            this.maxLoanAmountInTokens = +(this.paramsInformation.maxLoanAmount / this.loanAsset.exchangeRate * 1000000).toFixed(0) / 1000000;
            if (formattedVal > (this.maxLoanAmountInTokens)) {
              this.borrowForm.controls['amount'].setValue(this.maxLoanAmountInTokens, { emitEvent: false });
              this.borrowingService.updateLoanData({ loanAmount: this.maxLoanAmountInTokens })
            }
            if (this.borrowForm.controls['amount'].valid) {
              this.borrowingService.updateLoanData({
                loanAmount: formattedVal,
                loanAmountUsd: new BigNumber(formattedVal).times(this.loanAsset.exchangeRate).toNumber(),
                collateralAmount: this.loanCollateral ? new BigNumber(formattedVal).times(this.loanAsset.exchangeRate).times(this.borrowForm.value.collateralRatio).div(100).toNumber() : 0
              })
            }
            this.rewardsService.getUserRewardTypes(this.user ? this.user.ethAddress : undefined, this.loanAsset, this.loanData)
          }
          this.calculateTokenRewards(this.lastWeekRewards, this.loanData, this.loanAsset, this.rewardToken, this.loanOver1kAwarded);
        }
      })

    this.borrowForm.controls['duration'].valueChanges
      .pipe(debounceTime(50), takeUntil(this.destroy$))
      .subscribe((val) => {
        let formattedVal = val.toString();
        formattedVal.replace(/\D/g, '');
        if (formattedVal > this.paramsInformation.maxLoanTerm) {
          this.borrowForm.controls['duration'].setValue(this.paramsInformation.maxLoanTerm, { emitEvent: false });
          formattedVal = this.paramsInformation.maxLoanTerm;
          this.borrowingService.updateLoanData({ loanTerm: this.paramsInformation.maxLoanTerm })
        }
        if (formattedVal < this.paramsInformation.minLoanTerm) {
          this.borrowForm.controls['duration'].setValue(this.paramsInformation.minLoanTerm, { emitEvent: false });
          formattedVal = this.paramsInformation.minLoanTerm;
        }
        this.borrowingService.updateNetInterestRate(
          this.user ? this.user.creditData : this.defaultRiskRateFee,
          formattedVal,
          this.loanAsset ? this.loanAsset : this.assets[0],
          this.paramsInformation
        )
        const loanRequestValidity = new Date(Date.now() + 3 * 24 * 60 * 60 * 1000);
        this.borrowingService.updateLoanData({ loanTerm: val, loanRequestValidity });
        this.calculateTokenRewards(this.lastWeekRewards, this.loanData, this.loanAsset, this.rewardToken, this.loanOver1kAwarded);
      })



  }

  updateAmount() {
    this.minLoanAmountInTokens = +(this.paramsInformation.minLoanAmount / this.loanAsset.exchangeRate * 1000000).toFixed(0) / 1000000;
    this.maxLoanAmountInTokens = +(this.paramsInformation.maxLoanAmount / this.loanAsset.exchangeRate * 1000000).toFixed(0) / 1000000;
    this.borrowForm.controls['amount'].setValidators([
      Validators.min(this.minLoanAmountInTokens),
      Validators.max(this.maxLoanAmountInTokens),
      Validators.pattern(regEx.numbers),
      Validators.required
    ]);
    this.borrowForm.controls['amount'].setValue(this.presetValues.amount ? this.presetValues.amount : this.minLoanAmountInTokens, { emitEvent: true });
  }

  updateDuration(min: number) {
    this.borrowForm.controls['duration'].setValidators([
      Validators.min(min),
      Validators.max(this.paramsInformation.maxLoanTerm),
      Validators.pattern(regEx.numbers),
      Validators.required
    ]);
    this.borrowForm.controls['duration'].setValue(min, { emitEvent: true });
  }

  selectAsset(asset: any) {
    this.borrowForm.controls['asset'].setValue(asset.symbol, { emitEvent: true });

  }

  selectCollateral(collateral: any) {
    this.borrowForm.controls['collateral'].setValue(collateral.symbol, { emitEvent: true });
  }

  hasError(controlName: string, errorName: string) {
    return this.borrowForm.controls[controlName].hasError(errorName);
  }

  valuesCannotBeSame(controlName: string, otherControlName: string) {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup = control.parent;
      if (!formGroup) {
        return null;
      }

      const otherControl = formGroup.get(otherControlName);

      if (otherControl && control.value === otherControl.value) {
        return { valuesCannotBeSame: true };
      }
      return null;
    };
  }

  updateCreditLine() {
    this.borrowingService.inferCreditLineType(
      this.loanAsset ? this.loanAsset.symbol : this.assets[0],
      this.loanCollateral ? this.loanCollateral.symbol : this.collaterals[0]
    );
    forkJoin({
      userCreditLines: this.store.select(state => state.borrowing.creditLines)
        .pipe(filter(creditLines => !!creditLines && creditLines.length > 0), take(1)),
      loanData: this.store.select(state => state.borrowing.loanData)
        .pipe(filter(loanData => !!loanData), take(1))
    }).pipe(takeUntil(this.destroy$))
      .subscribe(({ userCreditLines, loanData }) => {
        this.loanData = loanData;
        this.userCreditLines = userCreditLines;
        this.borrowingService.getCreditLineAddress(userCreditLines, loanData.type);
        const cL = userCreditLines.find((cL: CreditLine) => cL.type === loanData.type);
        this.borrowingService.updateLoanData({ creditLineAddress: (cL ? cL.contractAddress : undefined) })
      })
  }

  borrowFormSubmitted() {
    this.isUpdating = true;

    this.openCreatingLoanDialog();
    this.store.dispatch(BorrowingActions.creatingLoanRequest({ creatingLoanRequest: true }));
    if (this.loanData.creditLineAddress) {
      this.createLoanRequest();
      this.isUpdating = false;
    } else {
      this.web3Service.createLine(this.loanData.type)
        .pipe(
          catchError((err: any): any => {
            this.store.dispatch(BorrowingActions.creatingLoanRequest({ creatingLoanRequest: false }));
            this.closeCreatingLoanDialog();
          }),
          takeUntil(this.destroy$))
        .subscribe((res) => {
          this.borrowingService.getCreditLines({ ownerAddress: this.user.ethAddress });
          const creditLineAddress = res.events.LoanContractCreated.returnValues.creditLine;
          this.borrowingService.updateLoanData({ creditLineAddress });
          this.closeCreatingLoanDialog();
          timer(10000).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
              this.isUpdating = false;
              this.createLoanRequest();
            });
        })
    }
  }

  createLoanRequest() {
    if (!this.advancedMode) {
      this.borrowingService.getCollateralToLoan(
        this.borrowForm.value,
        this.loanCollateral,
        this.loanAsset,
        this.user.creditData.riskRating
      )
    }

    // this.toastr.info('Sign the message on your wallet', 'Creating loan request!');
    // this.web3Service.signLoan(this.loanData)
    //   .pipe(catchError((err: any): any => {
    //     this.closeCreatingLoanDialog();
    //     this.store.dispatch(BorrowingActions.creatingLoan({ creatingLoan: false }));
    //   }),
    //     takeUntil(this.destroy$))
    //   .subscribe((signature: string) => {
    //     this.borrowingService.updateLoanData({ signature });
    //     this.closeCreatingLoanDialog();
    //     return this.borrowingService.createLoan(this.loanData);
    //   })

    if (this.isDialogOpen) {
      this.closeCreatingLoanDialog();
    }
    const loanId = this.web3Service.randomHex()
    this.borrowingService.updateLoanData({ loanId });
    this.borrowingService.createLoanRequest(this.loanData);


  }

  openCreatingLoanDialog() {
    this.isDialogOpen = true;
    this.dialog.open(CreateLoanDialog, {
      id: 'create-loan',
      minWidth: '300px',
      maxWidth: '500px',
      autoFocus: false,
      disableClose: true,
    });
  }

  addSeoConfig(seoConfig: any): void {
    this.title.setTitle(seoConfig.seoTitle)
    if (seoConfig.metaTags) {
      seoConfig.metaTags.forEach((tag: MetaDefinition) => {
        this.meta.addTag(tag);
      });
    }
  }

  showAdvanced(input: boolean) {
    const advancedRemoved$ = new Subject<void>();
    this.advancedMode = input;
    if (this.advancedMode) {
      let collateralValue = 0;
      if (this.loanData.collateralRatio > this.collateralRatioLimits.max) {
        collateralValue = this.collateralRatioLimits.max
      } else if (this.loanData.collateralRatio < this.collateralRatioLimits.min) {
        collateralValue = this.collateralRatioLimits.min
      } else {
        collateralValue = this.loanData.collateralRatio
      }
      this.borrowForm.addControl('collateralRatio', this.fb.control(collateralValue, [Validators.required, Validators.pattern(regEx.numbers), Validators.min(0), Validators.max(500)]));
      this.borrowForm.controls['collateralRatio'].valueChanges
        .pipe(debounceTime(500), takeUntil(merge(this.destroy$, advancedRemoved$)))
        .subscribe((val) => {
          if (val > this.collateralRatioLimits.max) {
            this.borrowForm.controls['collateralRatio'].setValue(this.collateralRatioLimits.max, { emitEvent: false });
            val = this.collateralRatioLimits.max;
          }
          if (val < this.collateralRatioLimits.min) {
            this.borrowForm.controls['collateralRatio'].setValue(this.collateralRatioLimits.min, { emitEvent: false });
            val = this.collateralRatioLimits.min;
          }
          const requiredCollateralValueUsd = parseFloat(
            new BigNumber(this.borrowForm.controls['amount'].value).times(this.loanAsset.exchangeRate).times(this.borrowForm.controls['collateralRatio'].value).div(100).toFixed(4)
          )
          const collateralAmount = parseFloat(
            new BigNumber(requiredCollateralValueUsd).div(this.loanCollateral.collateralPrice || this.loanCollateral.exchangeRate).toFixed(6)
          )
          this.borrowingService.updateLoanData({ collateralRatio: val, collateralAmount });
        })
    } else {
      this.borrowForm.removeControl('collateralRatio');
      advancedRemoved$.next();
    }
  }

  closeCreatingLoanDialog() {
    this.isDialogOpen = false;
    this.dialog.getDialogById('create-loan').close();
  }

  showModeDetails() {
    const modeDetailsDialogRef = this.dialog.open(SimpleAdvancedInfoComponent, {
      id: 'simple-advanced-info',
      minWidth: '300px',
      maxWidth: '500px',
      autoFocus: false,
      disableClose: false,
    });

    modeDetailsDialogRef.componentInstance.close
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        modeDetailsDialogRef.close();
      })
  }

  onOverlayOutsideClick() {
    this.overlayOutsideClicked = true;
    setTimeout(() => {
      this.overlayOutsideClicked = false;
    }, 100);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
