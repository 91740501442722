<div class="rounded-3xl border-slate-200 bg-white p-4">
  <h3>Cancel Purchase Request</h3>
  <p class="text-slate-500 font-medium mb-4">Are you sure you want to cancel this purchase request?</p>
  <p class="text-slate-500 mb-4">The purchase request will be terminated and you will not receive any funds!</p>
  <div class="flex items-center justify-between">
    <button class="default" (click)="onCloseClick()">No</button>
    <button class="warning" (click)="onTerminationConfirmed()">
      Yes, Cancel
    </button>
  </div>
</div>
